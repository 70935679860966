@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: $SPACING-SM;
  .section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 5rem;
      height: 5rem;
    }
    .value {
      font-size: $FONT-SIZE-XXXL;
      font-weight: 500;
      margin: 1rem 0;
    }
    .label {
      font-size: $FONT-SIZE-LG;
      color: $COLOR-GRAY-500;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
  .divider {
    width: 2px;
    background-color: $COLOR-GRAY-200;
    height: 100%;
  }
  .viewmore_button {
    background-color: $COLOR-WHITE;
    border: none;
    font-size: $FONT-SIZE-LG;
    color: $COLOR-ELECSUMGREEN;
    text-transform: uppercase;
    letter-spacing: 1.35px;
    font-weight: 500;
    padding: 1.5rem 2rem;
    &:hover {
      @include hover_clear_shadow();
    }
  }
}
