@import 'styles/Base';

.loading_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.root {
  padding: $LAYOUT-CONTENT-PADDING;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
  .header {
    margin-bottom: 1.5rem;
  }
  .titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    .loadingButtonFlex{
    display: flex;
 }
  .loadingButtonAddUser {
    min-width: 20rem;
     margin-right: 10px;
  }
    .loadingButton {
      min-width: 20rem;
    }
  }
  .table_wrapper {
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
    max-height: 60vh;
    .table {
      border-collapse: collapse;
      width: 100%;
      thead {
        border-bottom: 1px solid $COLOR-GRAY-600;
        tr {
          th {
            padding: 1.5rem 0;
            text-align: left;
            &:first-child {
              padding-left: 1.5rem;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1.5px solid $COLOR-GRAY-100;
          border-top: 1.5px solid $COLOR-GRAY-100;
          &:nth-child(odd) {
            background-color: $COLOR-WHITE;
          }
          &:nth-child(even) {
            background-color: $COLOR-GRAY-000;
          }
          &:hover {
            background-color: $COLOR-ELECSUMLOW;
            cursor: pointer;
            td {
              color: $COLOR-GRAY-700;
            }
          }
          td {
            padding: 2rem 0;
            font-size: $FONT-SIZE-MD;
            font-weight: 400;
            color: $COLOR-GRAY-500;
            &:first-child {
              padding-left: 1.5rem;
            }
            &.status {
              //border: 1px solid red;
            }
            &.sistemas {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .nodata {
    }
  }
}
