@import 'styles/Base';

.root {
  margin-top: 1rem;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_image {
      width: 48px;
      height: 48px;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
      letter-spacing: 0.15px;
      color: $COLOR-BLACK;
      .top {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 1rem;
        .units {
          margin-left: 0.5rem;
          font-weight: 400;
          font-size: $FONT-SIZE-MD;
          letter-spacing: 0.1px;
          color: $COLOR-GRAY-350;
        }
      }
      .units_down {
        font-size: $FONT-SIZE-SM;
        letter-spacing: 0.4px;
        font-weight: 400;
        color: $COLOR-GRAY-500;
        opacity: 0.87;

        &.co2 {
          color: $COLOR-BLACK;
        }
      }
    }
  }
}
