@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  .main {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-blend-mode: screen, normal;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, rgba($COLOR-ELECSUMGREEN, 0.8), rgba($COLOR-ELECSUMGREEN, 0.8)),
      linear-gradient(to bottom, $COLOR-WHITE 8%, rgba(0, 0, 0, 0.5));

    .app-header {
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
    }
  }
}
