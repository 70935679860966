@import 'styles/Base';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $SPACING-SM;
  .shareButton {
    gap: 0.8rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: $COLOR-ELECSUMGREEN;
    }
  }
}
