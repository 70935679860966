@import 'styles/Base';

nav {
  border-right: 0 !important;
}

.resultadoInforme {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}

.flexContainerInforme {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.flex {
  display: flex;
  div {
    margin-bottom: 0px;
  }
}

.containerForms {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  padding: 1rem;
  .containerForm {
    display: flex;
    align-items: flex-end;

    width: 100%;
  }
}
.containerGeneracio {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.posotionradio {
  flex: 1 1;
  display: grid;
  grid-template-columns: 1fr minmax(20rem, 35rem);
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin-right: 2rem;
}
.postflex {
  display: flex;
}
.postflexbutton {
  display: flex;
  align-items: flex-end;
  .buttonSecondary {
    background-color: #d4c12a;
  }

  button {
    margin-left: 10px;
    border-radius: 20px;
  }
}
.divBoton {
  margin-top: 20px;
}

.direccioncolum {
  display: flex;
  flex-direction: column;
}
.imagenChange {
  max-height: 400px;
}
.inputImagen {
  width: 80%;
  border: 1px solid #dddddd;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 300;
  color: #666666;
  border-radius: 0.2rem;
  margin: 10px;
}
.closebtnImagen {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.estlobasico {
  padding-left: 20px;
}
.centerImagen {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.styleDivgeneral {
  height: 100vh;
  max-width: 100%;
  border: 1px solid #ddd;
}

.imagenMosttrar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  border: 1px solid #ddd;
}

.imagenThumnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 10px; /* Some padding */
  width: 150px; /* Set a small width */
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Add a hover effect (blue shadow) */
.imagenThumnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.test {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.imagenButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.paddingTag {
  padding-right: 15px;
}
.SectionPotencias {
  margin-bottom: 0 !important;
}
.paddingCheckbox {
  padding-right: 15px;
  input {
    margin-right: 5px;
  }
}
.SectionTarfa {
  margin-top: 15px;
  margin-bottom: 0 !important;
  font-size: 1.5rem !important;
}
.containerPotencia {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

  justify-items: flex-end;
  div {
    width: 80%;
  }
}
.containerPotenciapRECIO {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: 100%;

  justify-items: flex-end;
  div {
    width: 100%;
  }
  label {
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.15px;
    font-size: 1.5rem !important;
    width: 100% !important;
  }
  input {
    margin-left: 7%;
    width: 93%;
  }
  margin-top: 20px;
}
.containerFormsImagen {
  margin-top: 20px;
  margin-bottom: 50px;
}

.botonGuardar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  width: 100%;
  button {
    margin-right: 2rem;
  }
}

.positionValidar {
  display: flex;
  justify-content: flex-end;
}

.ResultadosDocumentos {
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
  & > * {
    margin: 0 1rem;
  }
}
.fields > * {
  margin: 2rem 0;
}
.margenesAnothergrid {
  div {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .containerPotenciapRECIO {
    grid-template-columns: 75%;
  }
  .containerForms {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr)) !important;
    gap: 1.5rem;
    .containerForm {
      align-items: flex-start;
      display: grid;
      grid-template-columns: minmax(30rem, 1fr);
      row-gap: 1.5rem;
    }
  }
}
.imagenDatatable {
  img {
    width: 100px !important;
    height: 80px !important;
  }
}

.MargenTopCheckbox {
  margin-top: 15px;
  margin-bottom: 25px;
}
.linea {
  border-top: 1px solid black;
  height: 2px;
  padding: 0;
  margin: 20px auto 0 auto;
}

.containerFormsleft {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
  gap: 2rem;
  .containerForm {
    display: grid;
    grid-template-columns: minmax(30rem, 1fr);
    row-gap: 1.5rem;
  }
}
.save-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.4rem;
}

.actions_wrapper {
  // max-width: 144rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .action {
    width: 15rem;
    margin-left: 2rem;
  }
}

// Table
.table_wrapper {
  width: 100%;
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
  .nodata {
    color: $COLOR-GRAY-600;
    padding: 2rem 0;
  }
  grid-column: span 2;
}

// Checkbox Table
.checkboxTable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  text-transform: uppercase;
  color: $COLOR-GRAY-500;
  margin-bottom: 0;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    margin-left: 0;
    background-color: $COLOR-WHITE;
    margin-right: 0.5rem;
    &:focus {
      outline: none;
    }
    &:checked + span {
      color: $COLOR-BLACK;
      font-weight: 500;
    }
  }
  input[type='checkbox']::before {
    content: '\2714';
    position: absolute;
    font-size: $FONT-SIZE-MD;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type='checkbox']:checked::before {
    visibility: visible;
    background-color: $COLOR-ELECSUMGREEN;
  }
}

.informe {
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;
  width: auto;
}

.informeExcluido {
  margin-top: 2.4rem;
}

.errorMessage {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  border: 1px solid #cc0000;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 2rem;
}

.errorMessage p {
  margin: 0;
}

.SpinnerInforme {
  display: flex;
  justify-content: center;
}

.tiempoEjecutar {
  background-color: white;
  color: $COLOR-ELECSUMGREEN;
  padding: 10px;
  border: 1px solid $COLOR-ELECSUMGREEN;
  border-radius: 4px;
  font-weight: bold;
  width: auto;
}

.tiempoEjecutarGlobal {
  background-color: white;
  color: $COLOR-ELECSUMGREEN;
  border-radius: 4px;
  font-weight: bold;
  width: auto;
}

.informeMessage {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.informeMessageGlbal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tituloinforme {
  width: 100%;
  margin-bottom: 1rem !important;
  padding-bottom: 0rem !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 10px 0;
}

.loadingButtonSameSize {
  max-width: 200px;
  min-width: 200px;
}

.flexInformeFecha {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.pInformeFechaLabel {
  padding: 5px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
  font-weight: bold;
  width: max-content;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detailContainer {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 297px;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailTitle {
  font-weight: bold;
  margin: 0;
}

.arrow {
  margin: 0 5px;
}

.detailValue {
  margin: 0;
}

.detailContainer p {
  color: #333;
  font-size: 14px;
}

.sizeDiv {
  width: 100%;
}
