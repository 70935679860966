@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .total_text {
    font-weight: 700;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-BLACK;
    line-height: 1.33;
    letter-spacing: 0.4px;
    padding-left: 1.2rem;
  }
  .total_estimate {
    margin-right: -80px;
  }
  .total_estimate,
  .total_guarantee {
    font-size: $FONT-SIZE-LG;
    color: $COLOR-BLACK;
    line-height: 1.75;
    letter-spacing: 0.4px;
    padding-left: 1.2rem;
    &:after {
      content: 'kWh';
      margin-left: 5px;
    }
  }
  .total_guarantee {
    padding-right: 1.2rem;
  }
}
