@import 'styles/Base';

.container {
  border: none;
  background-color: $COLOR-GREY-LOW;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: $FONT-SIZE-SM;
  font-weight: 300;
  border-radius: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  border-radius: 5rem;
  height: 3.5rem;
  border: 1px solid transparent;
  &:focus-within {
    border-color: $COLOR-ELECSUMGREEN;
  }
  input {
    color: $COLOR-GREY-DARK;
    background-color: $COLOR-GREY-LOW;
    width: 100%;
    margin-left: 1rem;
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::-webkit-input-placeholder {
      text-transform: none;
    }
  }
  svg {
    height: 2rem;
    width: 2rem;
    fill: $COLOR-GRAY-400;
  }
}
