@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  label {
    width: 100%;
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-GRAY-500;

    margin-bottom: 0.4rem;
  }

  &.range {
    padding: 2rem 0rem;
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 0;
      .info {
        flex: 1;
      }
      .value {
        font-size: $FONT-SIZE-LG;
        font-weight: 700;
        letter-spacing: 0.25px;
        color: $COLOR-BLACK;
        padding: 0 2rem;
      }
    }
  }
}
