@import 'styles/Base';
.root {
  .wrapper {
    display: flex;
    flex-direction: column;
    .value {
      font-size: 5rem;
    }
    .label {
    }
  }
}
