@import 'styles/Base';
$HEADER_HEIGHT: 4rem;
$DROPDOWN_WIDTH: 35rem;
.root {
  width: $DROPDOWN_WIDTH;
  position: relative;
  z-index: 5;
  .header {
    width: 75%;
    background-color: $COLOR-WHITE;
    border: none;
    padding: 0.5rem 2rem;
    border: 1px solid $COLOR-GRAY-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.8rem;
    height: $HEADER_HEIGHT;
    &.show {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
    .value {
      font-weight: 600;
      padding-right: 2rem;
      text-align: left;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
    .icon {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .items {
    width: 100%;
    z-index: 1;
    position: absolute;
    top: $HEADER_HEIGHT;
    padding: 1.5rem;
    border: 1px solid $COLOR-GRAY-100;
    border-radius: 0 0.8rem 0.8rem 0rem;
    background-color: $COLOR-WHITE;
    list-style: none;
    display: none;
    &.show {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
    .item {
      display: flex;
      flex-direction: row;
      width: 100%;

      input[type='radio'] {
        appearance: none;
        &:hover {
          cursor: pointer;
        }
      }
      input[type='radio'] {
        position: relative;
        width: 18px;
        height: 18px;
        border: 2px solid $COLOR-ELECSUMGREEN;
        vertical-align: -4px;
        border-radius: 50%;
        color: $COLOR-WHITE;
        margin-left: 0;
        margin-right: 0.5rem;
      }
      input[type='radio']::before {
        content: '';
        display: flex;
        align-items: center;
        position: absolute;
        font-size: $FONT-SIZE-SM;
        visibility: hidden;
        width: 10px;
        height: 10px;
        margin-top: 2px;
        margin-left: 2px;
        border-radius: 50%;
      }
      input[type='radio']:checked::before {
        visibility: visible;
        background-color: $COLOR-ELECSUMGREEN;
      }
      &:hover {
        cursor: pointer;
      }
      label {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        color: $COLOR-GRAY-500;
        &.selected {
          color: $COLOR-BLACK;
          font-weight: 500;
        }
      }
    }
  }
}
