@import 'styles/Base';

.ElecsumGreenStamp {
  margin: 2rem 0;
  padding: 2rem 0;
  .input-group {
    margin-top: 24px;
    label {
      width: 100%;
      padding-left: 0.75rem;
      font-size: $FONT-SIZE-SM;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
    }
    .text-color-grey {
      color: $COLOR-XARXA;
    }
    .textAreaDescription {
      border: 1px solid #979797 !important;
      border-radius: 4px !important;
    }
  }
}

.root {
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    .title {
      flex: 1;
    }
    .consulta {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .form {
    .fields_container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      .fields_group {
        & > * {
          margin: 1.5rem 0;
        }
      }
    }
    .description {
      margin: 2rem 0;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 2rem;
      & > * {
        margin: 0 2rem;
      }
    }
  }
  .image-type {
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-GRAY-500;
  }
  .image-uploader-wrapper {
    position: relative;
    padding-bottom: 4rem;
    margin-bottom: 1.5rem;
    .uploadImageBtn {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: $FONT-SIZE-LG;
      min-width: 200px;
      padding: 1rem;
    }
    .image-fetched-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 60px;
      border-radius: 2px;
      border: 1px solid $COLOR-GRAY-100;
      z-index: 1;
      background-color: $COLOR-WHITE;
      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }
  }
  .image-elecsumGreenQr {
    margin: 2rem 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem;
    width: 90%;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    color: #666666;
    gap: 0.5rem;
  }

  select {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #666666;
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    max-height: 250px;
    border-radius: 8px;
    margin-top: 10px;
  }
  img:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.sync_loader {
  padding: 2rem;
}
