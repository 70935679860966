@import 'styles/Base';

.root {
  height: 100%;
  max-height: 100%;
  flex: 1;
  .info_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 1rem;
      .label {
        font-weight: 400;
        line-height: 2rem;
        letter-spacing: 0.25px;
        font-size: $FONT-SIZE-MD;
        color: $COLOR-GRAY-500;
        text-transform: uppercase;
      }
      .value {
        text-align: right;
        font-weight: 500;
        font-size: $FONT-SIZE-LG;
        text-align: right;
      }
    }
  }
  .members_wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    // max-height: 12rem;
    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;
  }
  .member {
    padding: 12px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $FONT-SIZE-MD;
    color: $COLOR-GRAY-500;
    &:first-child {
      border-top: 1px solid $COLOR-GRAY-100;
    }
    border-bottom: 1px solid $COLOR-GRAY-100;
    &.even {
      background-color: rgba($COLOR-ELECSUMGREEN, 0.2);
      color: $COLOR-BLACK;
      font-weight: 500;
    }
    .nombre {
      flex: 2;
      padding-left: 1rem;
    }
    .percent {
      flex: 1;
      text-align: center;
      align-self: center;
    }
    .generation {
      flex: 1;
      text-align: right;
      padding-right: 1rem;
    }
  }
}
