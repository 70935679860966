@import 'styles/Base';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 0.5rem;
  margin-bottom: 5rem;

  .left_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-weight: 600;
      margin-right: 2rem;
      font-size: $FONT-SIZE-XL;
    }
    .search_wrapper {
      width: 35rem;
    }
  }
  .filters {
    align-items: center;
    display: flex;
    gap: 2rem;
    flex-direction: row;
  }
}
.tiempoEjecutar {
  background-color: white;
  color: $COLOR-ELECSUMGREEN;
  padding: 10px;
  border: 1px solid $COLOR-ELECSUMGREEN;
  border-radius: 4px;
  font-weight: bold;
  width: auto;
  margin: auto;
}
