@import 'styles/Base';

.root {
  margin: $LAYOUT-CONTENT-PADDING;

  .invoices_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    width: 100%;
    background-color: $COLOR-WHITE;
    .invoices_chart {
      min-width: 60rem;
      @include desktop() {
        min-width: 60rem;
      }
      @include tablet() {
        min-width: 100%;
      }
      @include mobile() {
      }
      .picker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1rem;
        .picker_button {
          color: $COLOR-ELECSUMGREEN;
          background-color: transparent;
          font-size: $FONT-SIZE-XL;
          text-align: center;
          padding: 0.5rem 2rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          svg {
            margin: 0;
          }
          &:hover {
            @include hover_clear_shadow();
          }
        }
        .picker_value {
          margin: 0;
          padding: 0 4rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $COLOR-ELECSUMGREEN;
        }
      }

      .infopicked {
        display: flex;
        flex-direction: column;
        align-items: center;
        .month {
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 0.4px;
          font-size: $FONT-SIZE-SM;
          font-size: $FONT-SIZE-LG;
          color: $COLOR-GRAY-500;
        }
        .cost {
          margin-top: 1rem;
          font-weight: 600;
          font-size: $FONT-SIZE-XXL;
        }
      }
      .chart_svg {
        path {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .invoices {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 60rem;
    }
  }
}

.nodata {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}

.loading_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
