@import 'styles/Base';

$BASE_SIZE: 3.5rem;

.container {
  position: relative;
  .head {
    width: 100%;
    background-color: $COLOR-GREY-LOW;
    border-radius: 50rem;
    height: $BASE_SIZE;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    .label {
      flex: 1;
      text-align: center;
      font-size: $FONT-SIZE-MD;
      margin-right: 1rem;
      color: $COLOR-GREY-DARK;
    }
    &.open {
      svg {
        transform: rotateZ(-0deg);
      }
    }
    svg {
      transform: rotateZ(-90deg);
      width: 0.8rem;
      height: auto;
      fill: $COLOR-GREY-DARK;
      transition: transform ease-in 0.1s;
    }
  }
  .items {
    top: calc(#{$BASE_SIZE} + 1rem);
    left: 5%;
    max-height: 21rem;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 90%;
    display: none;
    position: absolute;
    z-index: 20;
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-GREY-MEDIUM;
    border-radius: 0.2rem;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */

    &.open {
      display: flex;
      flex-direction: column;
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      font-size: $FONT-SIZE-MD;
      padding: 1rem 1rem;
      &:hover {
        cursor: pointer;
        background-color: $COLOR-ELECSUMLOW;
      }
      span {
        flex: 1;
      }
      svg {
        width: auto;
        height: 1rem;
        fill: $COLOR-ELECSUMGREEN;
      }

      &--selected {
        background-color: $COLOR-ELECSUMLOW;
      }
    }
  }
  .itemsComunidad {
    top: calc(#{$BASE_SIZE} + 1rem);
    left: 5%;
    max-height: 21rem;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 90%;
    display: none;
    position: absolute;
    z-index: 20;
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-GREY-MEDIUM;
    border-radius: 0.2rem;
    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }

    &.open {
      display: flex;
      flex-direction: column;
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      font-size: $FONT-SIZE-MD;
      padding: 1rem 1rem;
      &:hover {
        cursor: pointer;
        background-color: $COLOR-ELECSUMLOW;
      }
      span {
        flex: 1;
      }
      svg {
        width: auto;
        height: 1rem;
        fill: $COLOR-ELECSUMGREEN;
      }

      &--selected {
        background-color: $COLOR-ELECSUMLOW;
      }
    }
  }
}

.header {
  position: relative;
  background-color: rgba($COLOR-WHITE, 0.85);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.input {
  margin-left: 1rem;
  padding: 0.5rem 0;
  background-color: transparent;
  border: none;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  color: $COLOR-GRAY-700;
  &::placeholder {
    font-weight: 300;
    color: $COLOR-GRAY-400;
  }
  flex: 1;
  &:focus {
    outline: none;
  }
}

.tableContainer {
  max-height: 200px; /* Ajusta la altura máxima según necesites */
  overflow-y: auto; /* Añade scroll si hay demasiados elementos */
  border: 1px solid #ccc; /* Borde alrededor de la tabla */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.itemRow:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.item--selected {
  background-color: #e0ffe0; /* Color para fila seleccionada */
}
