@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  .menu {
    padding: 1rem 1.5rem;
    background-color: $COLOR-WHITE;
    box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.120766);
    display: flex;
    flex-direction: row;
    gap: 2rem;
    .grouping {
      width: 100%;
      max-width: 15rem;
    }
    .types {
      width: 100%;
      max-width: 15rem;
    }
  }
  .chartWrapper {
    flex: 1;
    max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  }
}
