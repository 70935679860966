@import 'styles/Base';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.15);
    background-color: $COLOR-WHITE;
    padding: 4rem 2rem 2rem 2rem;
    .logo {
      width: 50%;
      margin: 0 auto;
      display: block;
      margin-bottom: 4rem;
    }
    .form {
      .helper {
        font-size: $FONT-SIZE-LG;
        margin-bottom: 2rem;
        color: $COLOR-GRAY-500;
      }
      .input {
        margin-bottom: 2.5rem;
      }
      .next_step_wrapper {
        margin-top: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          letter-spacing: 1.35px;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          border-radius: 0;
        }
      }
    }
  }

  .success_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50rem;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.15);
    background-color: $COLOR-WHITE;
    border-radius: 0.8rem;
    padding: 4rem;
    svg {
      margin-bottom: 2rem;
    }
    h4 {
      font-size: $FONT-SIZE-XL;
      color: $COLOR-GRAY-800;
      letter-spacing: 0.15px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    p {
      color: $COLOR-GRAY-500;
      text-align: center;
      font-weight: 400;
      line-height: 2.4rem;
      margin-bottom: 2rem;
    }
  }
}
