@import 'styles/Base';

.root {
  padding: 1rem;
  display: grid;
  grid-template-columns: 4rem 1fr;
  border: none;
  border-bottom: 2px solid $COLOR-GRAY-050;
  background-color: $COLOR-WHITE;
  &:hover {
    background-color: rgba($COLOR-GRAY-300, 0.1);
    cursor: pointer;
  }
  .icon_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .info_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      font-size: $FONT-SIZE-LG;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 2.4rem;
    }
    .subtitle {
      font-size: $FONT-SIZE-SM;
      letter-spacing: 0.4px;
    }
    .information {
      margin-top: 0.5rem;
      color: $COLOR-GRAY-500;
      line-height: 1.6rem;
      font-size: $FONT-SIZE-SM;
      text-align: left;
    }
  }
}
