@import 'styles/Base';

.root {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  .header {
    margin-bottom: 1rem;
  }
  .form {
    background-color: $COLOR-GRAY-000;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: row;
    .fields {
      flex: 1;
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.ElecsumGreenImages {
  margin-bottom: 2.5rem;
  .UploadImagesForm {
    background-color: $COLOR-GRAY-000;
    padding: 1.25rem 1.5rem 1.25rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .form-group {
      margin-bottom: 0;
    }
  }
}
