@import 'styles/Base';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
  .btn {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: $COLOR-ELECSUMGREEN;
    }
  }
  .text {
    color: $COLOR-GREY-DARK;
    padding: 0 1rem;
    margin: 0 1rem;
    font-size: $FONT-SIZE-MD;
  }
}
