@font-face {
  font-family: MontserratBold;
  src: local('MontserratBold'), url('../assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: MontserratSemiBold;
  src: local('MontserratSemiBold'), url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: MontserratRegular;
  src: local('MontserratRegular'), url('../assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: MontserratLight;
  src: local('MontserratLight'), url('../assets/fonts/montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Thin.ttf');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Black.ttf');
  font-weight: 900;
}
