@import 'styles/Base';

.root {
  margin: $LAYOUT-CONTENT-PADDING;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  .loading_wrapper {
    display: flex;
    flex-direction: row;

    justify-content: center;
  }
  .table_wrapper {
    table {
      border-collapse: collapse;
      width: 100%;
      .nodata {
        color: $COLOR-GRAY-600;
        padding: 2rem 0;
      }
      thead {
        border-bottom: 1px solid $COLOR-GRAY-600;
        tr {
          th {
            padding: 1.5rem 0;
            text-align: left;
            &:first-child {
              padding-left: 1.5rem;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1.5px solid $COLOR-GRAY-100;
          border-top: 1.5px solid $COLOR-GRAY-100;
          height: 6rem;
          &:nth-child(odd) {
            background-color: $COLOR-WHITE;
          }
          &:nth-child(even) {
            background-color: $COLOR-GRAY-000;
          }
          &:hover {
            background-color: $COLOR-GRAY-000;
            cursor: pointer;
            td {
              color: $COLOR-GRAY-700;
            }
          }
          td {
            padding: 1rem 0;
            font-size: $FONT-SIZE-MD;
            font-weight: 400;
            color: $COLOR-GRAY-500;
            letter-spacing: 0.5px;
            &:first-child {
              padding-left: 1.5rem;
            }
            &.hasInstallation {
              z-index: 10;
            }
          }
        }
      }
    }
  }
}

// Checkbox Table
.checkboxTable {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  text-transform: uppercase;
  color: $COLOR-GRAY-500;
  margin-bottom: 0;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    margin-left: 2rem;
    background-color: $COLOR-WHITE;
    margin-right: 0.5rem;
    &:focus {
      outline: none;
    }
    &:checked + span {
      color: $COLOR-BLACK;
      font-weight: 500;
    }
  }
  input[type='checkbox']::before {
    content: '\2714';
    position: absolute;
    font-size: $FONT-SIZE-MD;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type='checkbox']:checked::before {
    visibility: visible;
    background-color: $COLOR-ELECSUMGREEN;
  }
}

.saveContainer {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
  justify-content: flex-end;
}
