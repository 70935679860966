@import 'styles/Base';

.toast-success-container {
  background-color: $COLOR-WHITE !important;
  color: $COLOR-ELECSUMGREEN !important;
}

.toast-succes-progress {
  background-color: $COLOR-ELECSUMGREEN;
}
