@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  padding: $LAYOUT-CONTENT-PADDING;
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  & > * {
    margin-right: 2rem;
  }
}

.clientform {
  .fields_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    justify-content: space-between;
    .fields_column {
      background-color: $COLOR-WHITE;
      padding: 1.5rem;
    }
  }
  .fields_group {
    margin-bottom: 2rem;
    & > * {
      margin-bottom: 1.5rem;
    }
  }
}

.reports {
  .report_type {
    margin-bottom: 1.5rem;
    font-size: $FONT-SIZE-XS;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: $COLOR-BLACK;
    opacity: 0.87;
    text-transform: uppercase;
    .type {
      font-weight: 500;
      font-size: $FONT-SIZE-XS;
      font-weight: 500;
      color: $COLOR-BLACK;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    .checkBoxes {
      display: flex;
      flex-direction: row;
    }
  }
  .form-group.FormGroup {
    margin-top: 0.5rem;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
  & > * {
    margin: 0 1rem;
  }
}

.nodata {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}
