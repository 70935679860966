@import 'styles/Base';

.root {
  position: relative;
  z-index: 2;

  width: 100%;
  .header {
    position: relative;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    .logo {
    }
    .input {
      margin-left: 1rem;
      padding: 0.5rem 0;
      background-color: transparent;
      border: none;
      font-size: $FONT-SIZE-MD;
      font-weight: 400;
      color: $COLOR-GRAY-700;
      &::placeholder {
        font-weight: 300;
        color: $COLOR-GRAY-400;
      }
      flex: 1;
      &:focus {
        outline: none;
      }
    }
  }
  .dropdown_wrapper {
    display: none;
    height: 60vh;
    width: calc(100% + 10rem);
    background-color: $COLOR-WHITE;
    position: absolute;
    border-radius: 0.8rem;
    margin-top: 2px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    padding: 1rem;
    flex-direction: column;
    &.show {
      display: flex;
    }
    .dropdown_filters {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 0.5rem;
      margin-bottom: 1rem;
      .filter {
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        font-weight: 500;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        overflow: hidden;
        &:hover {
          box-shadow: 0rem 0rem 1rem rgba($COLOR-BLACK, 0.2);
        }
        svg {
          margin-right: 1rem;
        }
        &.selected {
          font-weight: 700;
        }
      }
    }
    .dropdown_items {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      .dropdown_item {
      }
    }
  }
}

.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
