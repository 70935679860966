@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  .value {
    font-size: $FONT-SIZE-XXXL;
    font-weight: 500;
    margin: 1rem 0;
  }
  .label {
    font-size: $FONT-SIZE-LG;
    color: $COLOR-GRAY-500;
    margin-bottom: 2.5rem;
  }
  .viewmore_button {
    background-color: $COLOR-WHITE;
    border: none;
    font-size: $FONT-SIZE-LG;
    color: $COLOR-ELECSUMGREEN;
    text-transform: uppercase;
    letter-spacing: 1.35px;
    font-weight: 500;
    padding: 1.5rem 2rem;
    &:hover {
      @include hover_clear_shadow();
    }
  }
}
