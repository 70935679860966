@import 'styles/Base';

.loading_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: $LAYOUT-CONTENT-PADDING;
  .formcontainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.6rem;
    gap: 1.6rem;
  }
}
.fields > * {
  margin: 2rem 0;
}

.toast-success {
  background: red;
}
.actions_container {
  display: flex;
  justify-content: flex-end;
  grid-column: span 3;
  .action {
    width: 15rem;
    margin-left: 2rem;
  }
}

.notselected {
  padding: 2rem 4rem;
  text-align: center;
}
