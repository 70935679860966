@import 'styles/Base';

$BASE_SIZE: 2rem;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
  padding-left: calc(#{$BASE_SIZE} + 1rem);
  height: $BASE_SIZE;
  cursor: pointer;
  font-size: $FONT-SIZE-MD;
  color: $COLOR-GREY-DARK;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    input ~ .checkmark {
      background-color: $COLOR-GREY-MEDIUM;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $COLOR-ELECSUMGREEN;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $BASE_SIZE;
    width: $BASE_SIZE;
    background-color: $COLOR-GREY-LOW;
    border-radius: 0.4rem;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.6rem;
  top: calc(#{$BASE_SIZE} / 10);
  width: 0.75rem;
  height: 1.25rem;
  border: solid $COLOR-WHITE;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
