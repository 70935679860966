@import 'styles/Base';

.btn {
  height: 3.5rem;
  padding: 0 1.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: $FONT-SIZE-MD;
  font-weight: 700;
  border-radius: 5rem;
  align-self: center;
  &:active,
  &:focus {
    outline: none;
  }
  &-stretch {
    width: fit-content;
  }
  &-primary {
    &__solid {
      background-color: $COLOR-ELECSUMGREEN;
      color: $COLOR-WHITE;
      &:hover {
        background-color: darken($COLOR-ELECSUMGREEN, 5);
      }
    }
    &__outlined {
      background-color: transparent;
      color: $COLOR-ELECSUMGREEN;
      border: 2px solid $COLOR-ELECSUMGREEN;
    }
    &__ghost {
      background-color: transparent;
      color: $COLOR-ELECSUMGREEN;
      &:hover {
        background-color: transparentize($color: $COLOR-ELECSUMGREEN, $amount: 0.85);
      }
    }
    &__clear {
      background-color: transparent;
      color: $COLOR-ELECSUMGREEN;
    }
  }
  &-reddelete {
    &__solid {
      background-color: $COLOR-ERROR;
      color: $COLOR-WHITE;
      &:hover {
        background-color: darken($COLOR-ERROR, 5);
      }
    }
    &__outlined {
      background-color: transparent;
      color: $COLOR-ERROR;
      border: 2px solid $COLOR-ERROR;
    }
    &__ghost {
      background-color: transparent;
      color: $COLOR-ERROR;
      &:hover {
        background-color: transparentize($color: $COLOR-ERROR, $amount: 0.85);
      }
    }
    &__clear {
      background-color: transparent;
      color: $COLOR-ERROR;
    }
  }
}
