@import 'styles/Base';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $SPACING-SM;
  align-items: center;
  .shareButton {
    gap: 0.8rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: $COLOR-ELECSUMGREEN;
    }
  }
}

.anotherContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $SPACING-SM;
  align-items: center;
  padding: 1.5rem;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
  .shareButton {
    gap: 0.8rem;
    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: $COLOR-ELECSUMGREEN;
    }
  }
}

.test {
  height: 1.5rem;

  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  padding: 1rem;

  background-color: transparent;
  border: none;
  border-radius: 5rem;
  align-self: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 200;
  color: $COLOR-GRAY-500;
  span {
    font-size: $FONT-SIZE-MD;
    font-weight: 200;
    padding: 1rem;
    color: $COLOR-GRAY-500;
  }
  &:active,
  &:focus {
    outline: none;
  }
  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: $COLOR-ELECSUMGREEN;
    margin: 0 0.5rem;
  }
}
