@import 'styles/Base';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .imageStamp {
    justify-items: center;
    text-align: center;
    //"text-center justify-items-center"
  }
  .button_wrapper {
    min-width: 15rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    .button {
      flex: 1;
      justify-content: center;
    }
  }
}
