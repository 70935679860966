@import 'styles/Base';

.root {
  margin: 2rem 0;
  .month,
  .year,
  .day {
    font-size: $FONT-SIZE-XXL;
    text-transform: capitalize;
    color: $COLOR-BLACK;
  }
}
