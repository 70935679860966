@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $NAVBAR-HEIGHT;
  background-image: linear-gradient(123deg, $COLOR-ELECSUMGREEN 58%, $COLOR-GRADIENT-YELLOW 129%);

  color: $COLOR-WHITE;

  .brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: $NAVBAR-BRAND-WIDTH;
    height: 100%;
    padding: 0;
    margin: 0;
    padding: 1rem;
    &:hover {
      background-color: rgba($COLOR-ELECSUMSTRONG, 0.2);
      box-shadow: 0rem 0rem 2rem rgba($COLOR-WHITE, 0.15);
    }
    img {
      width: 12rem;
      height: auto;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    .spinner {
      margin: auto;
    }
  }

  .cornerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      .button {
        padding: 1rem;
      }
      .notifications {
        color: $COLOR-WHITE;
        position: relative;
        border-radius: 50%;
        padding: 1.2rem 0.5rem;
        &:hover {
          background-color: rgba($COLOR-ELECSUMSTRONG, 0.1);
          box-shadow: 0rem 0rem 2rem rgba($COLOR-WHITE, 0.15);
        }
        svg {
          color: $COLOR-WHITE;
          height: 2rem;
          width: 2rem;
        }
      }
      .profileDropdown {
        padding: 0 2rem;
        position: relative;
        .dropdown {
          .head {
            position: relative;
            padding: 1.2rem;
            background-color: transparent;
            border: 0;
            color: $COLOR-WHITE;
            border-radius: 50%;
            &:hover {
              background-color: rgba($COLOR-ELECSUMSTRONG, 0.1);
              box-shadow: 0rem 0rem 2rem rgba($COLOR-WHITE, 0.15);
            }
            svg {
              height: 2rem;
              width: 2rem;
            }
            &:focus {
              outline: none;
            }
          }
          .items {
            z-index: 2;
            background-color: $COLOR-WHITE;
            display: none;
            position: absolute;
            margin-top: 1.2rem;
            width: calc(120% + 10rem);
            right: 1rem;
            border: 1px solid $COLOR-GRAY-100;
            border-radius: 4px;
            padding: 1rem;
            &.show {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
            .item {
              background-color: transparent;
              border: 0;
              padding: 0 1rem;
              color: $COLOR-BLACK;
              font-size: $FONT-SIZE-LG;
              display: flex;
              flex-direction: row;
              align-items: center;
              &.logout {
                color: $COLOR-ERROR;
              }
              &:focus {
                outline: none;
              }
              svg {
                margin-right: 1rem;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.selector {
  position: relative;
  display: block;
  min-width: 250px;
  color: $COLOR-WHITE;
  font-size: $FONT-SIZE-SM;
  height: 100%;
  &:hover,
  .toggled {
    background-color: rgba($COLOR-ELECSUMSTRONG, 0.2);
    box-shadow: 0rem 0rem 2rem rgba($COLOR-WHITE, 0.15);
  }

  .button {
    &:hover {
      cursor: pointer;
    }
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    color: $COLOR-WHITE;
    font-size: $FONT-SIZE-LG;
    border: none;
    padding: 0 1rem;
    .toggleDropwdown {
      margin-top: 1rem;
      align-self: flex-start;
      line-height: 2.4rem;
    }
    &:focus {
      outline: none;
    }

    .logo {
      background-color: $COLOR-WHITE;
      height: 3rem;
      width: 3.2rem;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin: 0 1.5rem;
      text-align: left;
      .name {
        font-weight: 700;
        font-size: $FONT-SIZE-LG;
        letter-spacing: 1px;
        line-height: 2.4rem;
      }
      .cups {
        font-weight: 400;
        font-size: $FONT-SIZE-SM;
        letter-spacing: 0.4px;
      }
      .address {
        font-weight: 400;
        font-size: $FONT-SIZE-SM;
        letter-spacing: 0.4px;
      }
      .promotor {
        font-weight: 400;
        font-size: $FONT-SIZE-SM;
        letter-spacing: 0.4px;
        text-transform: capitalize;
      }
    }
  }
  .dropdown {
    z-index: 2;
    position: absolute;
    margin-top: 0rem;
    width: calc(100% + 38rem);
    border-radius: 4px;
    border: 1px solid $COLOR-GRAY-100;
    background-color: $COLOR-WHITE;
    display: none;
    .dropdown-header {
      width: 100%;
      margin-bottom: 0;
      background-color: $COLOR-GRAY-050;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
      border-bottom: 1px solid $COLOR-XARXA;
      padding: 1rem 2rem;
    }
    &.show {
      display: block;
    }

    .items {
      padding: 1rem 0;
    }
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $FONT-SIZE-MD;
      font-weight: 400;
      letter-spacing: 1.35px;
      text-transform: uppercase;
      color: rgba($color: $COLOR-GRAY-500, $alpha: 0.8);
      padding: 1rem;
      label {
        margin: 0;
        line-height: 1;
      }
      input[type='radio'] {
        appearance: none;
        &:hover {
          cursor: pointer;
        }
      }
      input[type='radio'] {
        position: relative;
        width: 18px;
        height: 18px;
        border: 2px solid $COLOR-ELECSUMGREEN;
        vertical-align: -4px;
        border-radius: 50%;
        color: $COLOR-WHITE;
        margin-left: 0;
        margin-right: 0.5rem;
      }
      input[type='radio']::before {
        content: '';
        display: flex;
        align-items: center;
        position: absolute;
        font-size: $FONT-SIZE-SM;
        visibility: hidden;
        width: 10px;
        height: 10px;
        margin-top: 2px;
        margin-left: 2px;
        border-radius: 50%;
      }
      input[type='radio']:checked::before {
        visibility: visible;
        background-color: $COLOR-ELECSUMGREEN;
      }
    }
  }
}

.InstallationSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.5rem 1rem;
  min-width: 250px;
  color: $COLOR-WHITE;
  &:hover {
    color: $COLOR-WHITE;
    text-decoration: none;
  }

  .info {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: $FONT-SIZE-LG;
      font-weight: 700;
    }
    .city,
    .state {
      font-size: $FONT-SIZE-XS;
    }
  }
}
.ClientSelected {
  .button {
    background-color: rgba($COLOR-BLACK, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .toggleDropwdown {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }
}

.TecnicHeader {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr minmax(20rem, 35rem);
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-right: 2rem;
  .search_wrapper {
    width: 100%;
    max-width: 40rem;
    justify-self: flex-end;
  }
}
