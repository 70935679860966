@import 'styles/Base';

.paris_card {
  flex: 1;
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  justify-content: space-around;
  .ue_container {
    justify-items: center;
    text-align: center;
    //"text-center justify-items-center"
  }
  .spain_container {
    text-align: center;
  }
  .title {
    text-transform: uppercase;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-GRAY-500;
  }
  .text {
    margin: 0;
    margin-left: 1rem;
    font-size: $FONT-SIZE-SM;
    line-height: 1;
    color: $COLOR-GRAY-350;
  }
  .text-bold {
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-GRAY-500;
  }
  .data_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    .data {
      margin: 0;
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
    }
  }
}
