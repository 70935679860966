@import 'styles/Base';

/* MonthYearSwitcher.module.scss */

.monthYearSwitcherContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  gap: 10px;
}

.switchButton {
  background-color: $COLOR-ELECSUMGREEN;
  color: #fff;
  border: none;
  border-radius: 100%;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switchButton:hover {
  background-color: $COLOR-ELECSUMGREEN;
  opacity: 0.7;
}

.switchButton:disabled {
  background-color: $COLOR-ELECSUMGREEN;
  opacity: 0.2;
}

.dateDisplay {
  font-size: 18px;
  font-weight: bold;
}
