@import 'styles/Base';

.root {
  align-items: center;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  letter-spacing: 1.35px;
  padding: 1.25rem 2rem;
  text-align: center;
  text-transform: uppercase;
  &.block {
    display: block;
    width: 100%;
  }
  &:disabled {
    opacity: 0.7;
  }
  &:hover {
    box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.1);
    transform: translateY(-0.1rem);
  }

  &.primary {
    background-color: $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    border: 0;
    &:hover {
      background-color: darken($COLOR-ELECSUMGREEN, 5);
    }
    &:active,
    &:focus {
      background-color: $COLOR-ELECSUMSTRONG !important;
      border-color: $COLOR-GRAY-100 !important;
      outline: 0;
      box-shadow: none !important;
    }
  }
  &.secondary {
    color: $COLOR-ELECSUMGREEN;
    background-color: $COLOR-WHITE;
    border: 0;

    &:active {
      background-color: $COLOR-ELECSUMGREEN !important;
    }
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }
  &.reddelete {
    background-color: $COLOR-ERROR;
    color: $COLOR-WHITE;
    &:hover {
      background-color: darken($COLOR-ERROR, 5);
    }
    &:active {
      background-color: $COLOR-ERROR !important;
    }
    &:focus {
      background-color: $COLOR-ERROR;
      border-color: $COLOR-ERROR;
      outline: 0;
      box-shadow: none;
    }
    &:disabled {
      background-color: rgba($COLOR-ERROR, 0.5);
    }
  }
}
