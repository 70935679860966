@import 'styles/Base';

.root {
  & {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background: $COLOR-ELECSUMGREEN;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-top: 0px;
  }

  &::-moz-range-thumb {
    height: 16px;
    width: 16px;
    background: $COLOR-ELECSUMGREEN;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
