@import 'styles/Base';

.root {
  margin-bottom: 0.75rem;
  color: $COLOR-BLACK;
  font-weight: 500;
  font-size: $FONT-SIZE-LG;
  line-height: 2.4rem;
  letter-spacing: 0.15px;
}
