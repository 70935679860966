@import 'styles/Base';

.root {
  .data_footer {
    margin-top: 2rem;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -50px;
    button {
      background-color: transparent;
      border: none;
      opacity: 0.5;
      svg {
        height: auto;
        width: 1.5rem;
      }
    }
  }
}

.list_item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.type_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.value_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 1rem;
}
.text {
  padding: 0;
  margin: 0;
  font-size: $FONT-SIZE-LG;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: $COLOR-GRAY-500;
}
.data {
  color: $COLOR-GRAY-500;
  font-weight: 500;
  font-size: $FONT-SIZE-XL;
  margin-bottom: 0;
  letter-spacing: 0.15px;
}
.unit {
  margin-left: 1rem;
  font-size: $FONT-SIZE-MD;
  letter-spacing: 0.1px;
  color: $COLOR-GRAY-350;
  width: 35px;
}

.nodata {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}
