@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  padding: 0 3rem;
  background-color: $COLOR-WHITE;
  box-shadow: 0rem 1rem 1.5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 20;
  .option {
    background-color: $COLOR-WHITE;
    border: none;
    border-bottom: 3px solid transparent;
    color: $COLOR-GRAY-500;
    font-size: $FONT-SIZE-MD;
    font-weight: 500;
    letter-spacing: 1.35px;
    height: 5rem;
    padding: 1.5rem 4rem;
    text-transform: uppercase;
    &:focus {
      outline: none;
    }
    &.selected {
      color: $COLOR-BLACK;
      font-weight: 500;
      border-bottom: 3px solid $COLOR-ELECSUMGREEN;
    }
  }
}
