.wrapper {
  display: grid;
  border-radius: 0.6rem;
  overflow: hidden;

  .opt {
    background-color: #f7f7f7;
    border: none;
    color: #666666;
    font-family: 'Comfortaa', sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: normal;
    padding: 0.8rem 0.8rem;
    -webkit-transition: background-color 0.25s ease-out;
    -moz-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;
    display: -moz-box;
    display: flex;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    &:hover {
      cursor: pointer;
    }
    &.active {
      background-color: var(--custom-color--primary, #98c832);
      color: #ffffff;
      svg {
        fill: #ffffff;
        width: 20px;
        height: 20px;
      }
    }
  }
}
