@import 'styles/Base';

.root {
  .paginator_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0 2rem 0;
    .btn_wrapper {
      background-color: $COLOR-TRANSPARENT;
      border: none;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    .current {
      text-transform: capitalize;
      color: $COLOR-ELECSUMGREEN;
      padding: 0 3rem;
    }
  }
}

.year_zero {
  display: flex;
  flex-direction: row;
  justify-content: left;
  .message {
    text-align: center;
  }
}

.listitem {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .leftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 1rem;
    }
    .text {
      padding: 0;
      margin: 0;
      font-size: $FONT-SIZE-LG;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
    }
  }
  .rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .data {
      color: $COLOR-GRAY-500;
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
      margin-bottom: 0;
      letter-spacing: 0.15px;
    }
    .unit {
      margin-left: 1rem;
      font-size: $FONT-SIZE-MD;
      letter-spacing: 0.1px;
      color: $COLOR-GRAY-350;
      width: 35px;
    }
  }
}
