@import 'styles/Base';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.15);
    background-color: $COLOR-WHITE;
    padding: 4rem;
    .logo {
      width: 50%;
      margin: 0 auto;
      display: block;
      margin-bottom: 4rem;
    }
    .form {
      .input {
        margin-bottom: 2.5rem;
      }
    }
  }
  .steps_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
  }
}

.next_step_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.pill_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .pill {
    height: 4rem;
    width: 4rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
    margin-bottom: 2.5rem;
    &.active {
      background-color: $COLOR-ELECSUMGREEN;
      color: $COLOR-WHITE;
      box-shadow: 0rem 0rem 0.5rem $COLOR-ELECSUMGREEN;
    }
    &.completed {
      background-color: $COLOR-GRAY-050;
    }
    &.pending {
      background-color: $COLOR-GRAY-050;
      color: $COLOR-GRAY-500;
    }
  }
  .label {
    padding: 0rem 2rem;
    font-size: $FONT-SIZE-MD;
    position: absolute;
    bottom: 0;
    &.active {
    }
    &.completed {
      color: $COLOR-GRAY-500;
    }
    &.pending {
    }
  }
}

.divider {
  flex: 1;
  height: 2px;
  margin-top: 2rem;
  &.current {
    background: linear-gradient(90deg, $COLOR-GRAY-200 0%, $COLOR-GRAY-200 60%, $COLOR-GRAY-100 60%);
  }
  &.pending {
    background-color: $COLOR-GRAY-100;
  }
  &.completed {
    background-color: $COLOR-GRAY-200;
  }
}

.error {
  font-size: $FONT-SIZE-SM;
  color: $COLOR-ERROR;
  line-height: 1.4rem;
}
.error_high {
  font-size: $FONT-SIZE-MD;
  padding: 1rem 2rem;
  background-color: rgba($COLOR-ERROR, 0.1);
  color: $COLOR-ERROR;
  margin-bottom: 2rem;
  border-radius: 0.4rem;
}

.success_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.15);
  background-color: $COLOR-WHITE;
  border-radius: 0.8rem;
  padding: 4rem;
  svg {
    margin-bottom: 2rem;
  }
  h4 {
    font-size: $FONT-SIZE-XL;
    color: $COLOR-GRAY-800;
    letter-spacing: 0.15px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  p {
    color: $COLOR-GRAY-500;
    text-align: center;
    font-weight: 400;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  }
}
