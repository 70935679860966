@import 'styles/Base';

.root {
  width: 100%;
  border: 1px solid $COLOR-GRAY-100;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-size: $FONT-SIZE-MD;
  font-weight: 300;
  color: $COLOR-GRAY-500;
  border-radius: 0.2rem;
  &:focus {
    outline: none;
    border: 2px solid $COLOR-ELECSUMGREEN;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    text-transform: none;
  }
}
