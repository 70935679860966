$color_1: #333;
$background-color_1: #fff;
$background-color_2: transparent;
$background-color_3: #f2f2f2;

.dropdown {
	position: relative;
	display: inline-block;
}
.dropdown-toggle {
	background-color: $background-color_1;
	border: 1px solid #ccc;
	color: $color_1;
	padding: 8px 16px;
	font-size: 16px;
	cursor: pointer;
}
.dropdownMenu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	background-color: $background-color_1;
	border: 1px solid #ccc;
	border-top: none;
	min-width: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	>button {
		width: 100%;
		width: 90%;
		margin: auto;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: 8px 16px;
	font-size: 16px;
	color: $color_1;
	background-color: $background-color_2;
	border: none;
	cursor: pointer;
	&:hover {
		background-color: $background-color_3;
	}
}
