@import 'styles/Base';

.root {
  width: 100%;
  label {
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    color: $COLOR-GRAY-500;
  }
  select {
    width: 100%;
    border: 1px solid $COLOR-GRAY-100;
    padding: 1.25rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    font-size: $FONT-SIZE-MD;
    font-weight: 200;
    padding-right: 2rem;
    color: $COLOR-GRAY-500;
    border-radius: 0.2rem;
    &:focus {
      outline: none;
    }
  }
}
