@import 'styles/Base';

.root {
  margin: $LAYOUT-CONTENT-PADDING;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  .loading_wrapper {
    display: flex;
    flex-direction: row;

    justify-content: center;
  }
  .table_wrapper {
    table {
      border-collapse: collapse;
      width: 100%;
      .nodata {
        color: $COLOR-GRAY-600;
        padding: 2rem 0;
      }
      thead {
        border-bottom: 1px solid $COLOR-GRAY-600;
        tr {
          th {
            padding: 1.5rem 0;
            text-align: left;
            &:first-child {
              padding-left: 1.5rem;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1.5px solid $COLOR-GRAY-100;
          border-top: 1.5px solid $COLOR-GRAY-100;
          height: 6rem;
          &:nth-child(odd) {
            background-color: $COLOR-WHITE;
          }
          &:nth-child(even) {
            background-color: $COLOR-GRAY-000;
          }
          &:hover {
            background-color: $COLOR-GRAY-000;
            cursor: pointer;
            td {
              color: $COLOR-GRAY-700;
            }
          }
          td {
            padding: 1rem 0;
            font-size: $FONT-SIZE-MD;
            font-weight: 400;
            color: $COLOR-GRAY-500;
            letter-spacing: 0.5px;
            &:first-child {
              padding-left: 1.5rem;
            }
            &.hasInstallation {
              z-index: 10;
            }
          }
        }
      }
    }
  }
}
