@import 'styles/Base';

.root {
  padding: $LAYOUT-CONTENT-PADDING;
  .header {
    margin-bottom: 1rem;
  }
  .chartWrapper {
    background-color: $COLOR-WHITE;
    padding: 2rem;
    max-width: 85rem;
  }

  .grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
    background-color: rgba($COLOR-GRAY-100, 0.7);
    padding: 2px;
    .item {
      background-color: $COLOR-WHITE;

      &.expand {
        grid-column: span 2;
      }
    }
  }
}

.DataItem {
  padding: 2rem;
  display: flex;
  flex-direction: row;

  p {
    margin: 0;
  }
  .type {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      font-size: $FONT-SIZE-SM;
      padding-left: 1rem;
      line-height: 1;
      color: $COLOR-GRAY-500;
    }
  }
  .value {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 1;
    .data {
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
      padding-right: 1rem;
    }
    .unit {
      text-align: right;
      font-weight: 400;
      font-size: $FONT-SIZE-MD;
      color: $COLOR-GRAY-300;
      width: 3.5rem;
    }
  }
}

.currentPicker {
  background-color: transparent;
  color: $COLOR-ELECSUMGREEN;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  &:hover {
    @include hover_clear_shadow();
  }
}

.calendarStyle {
  margin-top: 2rem;
  transform: scale(1.25);
  font-size: $FONT-SIZE-SM;
}
.selected-day {
  background-color: $COLOR-ELECSUMGREEN;
}

.selected-day:hover {
  background-color: rgba($COLOR-ELECSUMGREEN, 0.8);
}

.loading_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
