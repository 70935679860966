@import 'styles/Base';

.container {
  display: flex;
  flex-direction: column;
  label {
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    margin-bottom: 0.4rem;
    color: $COLOR-GRAY-500;
  }
  input {
    align-items: center;
    background-color: transparent;
    border-radius: 0.2rem;
    border: 1px solid $COLOR-GRAY-100;
    color: $COLOR-GRAY-500;
    display: flex;
    flex-direction: row;
    font-size: $FONT-SIZE-MD;
    font-weight: 200;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    &:focus {
      outline: none;
      border: 2px solid $COLOR-ELECSUMGREEN;
    }
  }
}
