@import 'styles/Base';

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}

$CONTENT-MAXWIDTH: 1140px;

.root {
  background: url('../../assets/images/background-login.png') no-repeat center center fixed;
  background-size: cover;
  background-attachment: scroll;
  padding: 0.5rem;
  padding-bottom: 4rem;
  overflow-x: hidden;
  .header {
    max-width: $CONTENT-MAXWIDTH;
    margin: 0 auto;
    padding: 0 2rem;
  }
  .background_company_image {
    width: 100vw;
    max-height: 15rem;
    img {
      width: 100%;
      max-height: 15rem;
      object-fit: cover;
      object-position: center;
    }
  }
  .content_wrapper {
    max-width: $CONTENT-MAXWIDTH;
    margin: 0 auto;
    .drow {
      display: flex;
      flex-direction: row;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .company_logo_wrapper {
      height: auto;
      background-color: $COLOR-WHITE;
      margin-top: -45px;
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 13rem;
      }
      // .company_logo {
      //   border-radius: 6px;
      //   box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
      //   border: solid 0.7px #f1f1f1;
      // }
    }
    .company_contact {
      border-right: 1px solid $COLOR-GRAY-300;
      @media (max-width: 767px) {
        border-right: none;
        text-align: center;
        width: 100%;
      }

      padding: 2rem;
      width: 26rem;
      .company_name {
        font-weight: 700;
        font-size: $FONT-SIZE-LG;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $COLOR-BLACK;
        margin-bottom: 0;
      }
      .company_tel,
      .company_email {
        font-size: $FONT-SIZE-SM;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: $COLOR-BLACK;
        opacity: 0.87;
        margin-bottom: 0;
      }
      .company_web {
        font-weight: 500;
        font-size: $FONT-SIZE-MD;
        letter-spacing: 1.35px;
        color: $COLOR-ELECSUMGREEN;
        text-transform: uppercase;
        opacity: 0.87;
        margin: 0.5rem 0 1rem 0;
        a {
          color: $COLOR-ELECSUMGREEN;
        }
      }
    }

    .greenEnergy_wrapper {
      .elecsum_title {
        display: none;
        margin: 2rem 2rem;
        border-bottom: 2px solid $COLOR-ELECSUMGREEN;
        padding: 0.5rem 0rem;
        @media (max-width: 767px) {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
        .title_image {
          height: 4rem;
          width: 4rem;
          img {
            width: 100%;
          }
        }
        p {
          padding-left: 1rem;
          text-transform: uppercase;
          font-size: $FONT-SIZE-MD;
          opacity: 0.87;
        }
      }
      .data {
        display: flex;
        flex-direction: row;
        padding: 0 2rem;
        .logo_wrapper {
          width: 6rem;
          @media (max-width: 767px) {
            min-width: 6rem;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .greenEnergy_claim {
          max-width: 32rem;
          .claim {
            padding-left: 2rem;
            font-size: $FONT-SIZE-MD;
            line-height: 1.43;
            color: $COLOR-BLACK;
            opacity: 0.87;
          }
        }
      }
    }

    .updatedAt,
    .certified {
      margin-top: 1rem;
      padding: 1rem 0;
      font-size: $FONT-SIZE-SM;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
      opacity: 0.87;
      @media (max-width: 767px) {
        text-align: center;
        width: 100%;
      }
    }

    .production {
      .title {
        border-bottom: 2px solid $COLOR-GENERACIO;
      }
    }
    .consumption {
      .title {
        border-bottom: 2px solid $COLOR-ELECSUMGREEN;
      }
    }

    .widgets_container {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        justify-items: center;
      }
      gap: 1.5rem;
      .widget_section {
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          padding-bottom: 0.5rem;
          font-weight: 400;
          font-size: $FONT-SIZE-MD;
          line-height: 1.43;
          color: $COLOR-BLACK;
          opacity: 0.87;

          text-transform: uppercase;

          p {
            padding-left: 1rem;
          }
        }

        .slider {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          @media (max-width: 767px) {
            width: 85vw;
            &::-webkit-scrollbar {
              display: block;
            }
          }
          .widget {
            @media (max-width: 767px) {
              min-width: 70vw;
            }
            margin: 1rem;
            .card_style {
              h5 {
                font-size: $FONT-SIZE-LG;
                margin-bottom: 1rem;
              }
              h6 {
                font-size: $FONT-SIZE-MD;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .certifications {
    margin: 2rem auto;
    max-width: 60rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      max-width: 40rem;
      & > * {
        margin-bottom: 2rem;
      }
    }
    .certificate {
      padding: 0 2rem;

      .logos_wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1.5rem;

        .icon_wrapper {
          width: 24px;
          height: 19px;
          svg {
            width: 100%;
            height: 100%;
            float: left;
          }
        }
        .logo_wrapper {
          width: 55px;
          height: 18px;
          margin-left: 0.5rem;
          .caldense-logo {
            width: 100%;
            height: 100%;
            float: left;
          }
        }
      }
      .certified {
        font-weight: 400;
        font-size: $FONT-SIZE-SM;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: $COLOR-GRAY-500;
        opacity: 0.87;
      }
      .company_certificate {
        font-weight: 500;
        font-size: $FONT-SIZE-MD;
        letter-spacing: 1.35px;
        color: $COLOR-ELECSUMGREEN;
        text-transform: uppercase;
        opacity: 0.87;
        margin: 0.5rem 0 1rem 0;
        a {
          color: $COLOR-ELECSUMGREEN;
        }
      }
    }
  }
  .contactus {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .more_info {
      margin: 2rem 0 1rem 0;
      .text {
        font-weight: 400;
        font-size: $FONT-SIZE-LG;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $COLOR-BLACK;
        opacity: 0.87;
        margin-bottom: 0;
      }
    }
    .button_wrapper {
      padding: 2rem 4rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      .contact_link {
        width: 100%;
        max-width: 34rem;
        background-color: $COLOR-ELECSUMGREEN;
        color: $COLOR-WHITE;
        padding: 1.5rem;
        border-radius: 4px;
        font-weight: 500;
        font-size: $FONT-SIZE-MD;
        text-align: center;
        letter-spacing: 1.35px;
        text-transform: uppercase;
      }
    }
  }
}

.DisplayEnergyData {
  font-weight: 500;
  font-size: $FONT-SIZE-XL;
  letter-spacing: 0.15px;
  color: $COLOR-BLACK;
  margin-bottom: 1rem;
}

// MOBILE

@media (max-width: 767px) {
  .ElecsumGreenWebPage {
    .logo {
      width: 99px;
      height: 74px;
      padding: 0;
      margin-bottom: -15px;
      svg {
        width: 100%;
      }
    }
    .backgroung-company-image {
      height: 120px;
      max-height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .company-logo-wrapper {
      margin-left: auto;
      margin-right: auto;
      img {
        width: 100%;
      }
      .company-logo {
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
        border: solid 0.7px #f1f1f1;
      }
    }
    .company-contact {
      border-right: none;
      text-align: center;
      .company-web {
        margin-bottom: 24px;
      }
    }
    .greenEnergy-claim {
      max-width: 315px;
      .claim {
        padding: 0 14px;
      }
    }
    .no-pr-mobile {
      padding-right: 0;
    }
    .certified {
      font-size: $FONT-SIZE-SM;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
      opacity: 0.87;
    }
    .slider {
      scroll-snap-type: x mandatory;
      .slider-row {
        width: 180vw;
        margin-bottom: 10px;
        .slider-child {
          scroll-snap-align: start;
        }
      }
    }
    .certificate-1,
    .certificate-2 {
      padding: 0 1rem;
    }
  }
  ::-webkit-scrollbar {
    margin-top: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.1);
  }
}
