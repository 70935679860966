@import 'styles/Base';

.mainrow {
  border-top: 1px solid $COLOR-GREY-MEDIUM;
  border-bottom: 1px solid $COLOR-GREY-MEDIUM;
  &.even {
    background-color: $COLOR-WHITE;
  }
  &.odd {
    background-color: $COLOR-GRAY-000;
  }

  td {
    padding: 1rem 0;
    font-size: $FONT-SIZE-MD;
    font-weight: 400;
    color: $COLOR-GREY-DARK;
    &:first-child {
      padding-left: 1.5rem;
    }
    &.collapse {
      button {
        background-color: transparent;
        border: none;
        padding: 0 0.5rem;

        svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: $COLOR-ELECSUMGREEN;
        }
      }
    }
    &.cups {
      color: $COLOR-GREY-DARK;
      font-weight: 500;
    }
    .actions {
    }
  }
}
