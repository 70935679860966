@import 'styles/Base';

.root {
  display: grid;
  padding: $LAYOUT-CONTENT-PADDING;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(2, calc(min(50%, 520px)));
  gap: 2rem;
  .fields > * {
    margin: 2rem 0;
  }
  .userdata_container,
  .passworddata_container {
    background-color: $COLOR-WHITE;
    padding: 1.5rem;
  }
  .save-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.4rem;
  }

  .save-container-delete {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2.4rem;
    align-items: center;
    gap:10px;
    cursor: pointer;


    p{
      text-decoration: underline;
      color:red;

    }
    p:hover{
opacity:0.5
    }
  }
  .secondary {
    background-color: red;

  }
}
