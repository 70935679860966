@import 'styles/Base';
.loading_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.root {
  margin: $LAYOUT-CONTENT-PADDING;
}
