@import 'styles/Base';

.root {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  .data_text_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    .value {
      font-size: $FONT-SIZE-XL;
      font-weight: 500;
      color: $COLOR-BLACK;
      padding: 0 1rem;
      line-height: 1.8rem;
    }
    .total {
      line-height: 1.8rem;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
    }
  }
}

.flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 5px 0;
  background-color: $COLOR-ELECSUMLOW;

  .icon {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .text_container {
    padding: 0.5rem 0;
    max-width: 80%;
    .text {
      margin: 0;
      font-size: $FONT-SIZE-MD;
      color: $COLOR-GRAY-500;
    }
  }
}
