@import 'styles/Base';

.title {
  font-size: $FONT-SIZE-XL;
  font-weight: 700;
  margin-bottom: 1rem;
}

.subtitle {
  font-weight: 500;
  font-size: $FONT-SIZE-LG;
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section {
  background-color: $COLOR-WHITE;
  padding: 2rem;
  .totalWrapper {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 10rem;
    .label {
      font-weight: 800;
      text-align: right;
    }
    .value {
      text-align: right;
      font-weight: 500;
    }
  }
}
.divider {
  height: 2px;
  width: 100%;
  background-color: $COLOR-BLACK;
}

.root {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    thead {
      text-align: left;
      font-size: $FONT-SIZE-MD;
      line-height: 2rem;
      background-color: $COLOR-GRAY-050;
      tr {
        th {
          font-weight: 500;
          padding: 0.5rem;
          color: $COLOR-BLACK;
        }
      }
    }
    tbody {
      font-size: $FONT-SIZE-SM;
      tr {
        td {
          padding: 0.5rem;
        }
      }
    }
    tbody,
    thead {
      .total {
        text-align: right;
        width: 10rem;
      }
      .power {
        width: 7.5rem;
      }
      .period {
      }
      .consume {
        width: 10rem;
        text-align: right;
      }
      .maximeter {
        text-align: right;
        width: 10rem;
      }
    }
  }
  .menu {
    padding: 1rem 1.5rem;
    background-color: $COLOR-WHITE;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    .options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: flex-end;
    }
    .actions {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      .btn {
        height: 4.2rem;
      }
    }
    .powers_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      & > * {
        max-width: 10rem;
      }
    }
    .inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .simulation_wrapper {
    margin: 1rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
    max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
    gap: 2rem;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .resumen_simulacion {
      background: linear-gradient(108.91deg, $COLOR-GRADIENT-GREEN 56.54%, $COLOR-GRADIENT-YELLOW 107.98%);
      padding: 2rem;
      .heading {
        color: $COLOR-WHITE;
        font-weight: bold;
        font-size: $FONT-SIZE-XL;
        margin-bottom: 1.5rem;
      }
      .data {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
        .concept {
          color: $COLOR-WHITE;
          align-self: flex-end;
          .label {
            text-align: right;
            text-transform: uppercase;
            font-size: $FONT-SIZE-MD;
            line-height: 2.4rem;
            font-weight: 400;
          }
          .stronglabel {
            text-transform: uppercase;
            font-size: $FONT-SIZE-SM;
            line-height: 2.4rem;
            font-weight: bold;
          }
          .value {
            text-align: right;
            font-size: $FONT-SIZE-XL;
            font-weight: 500;
            line-height: 2rem;
          }
          .strongValue {
            font-size: $FONT-SIZE-XXXL;
            font-weight: 700;
          }
        }
        .total {
          grid-column: span 2;
          text-align: right;
        }
      }
    }
    .datosContratacion {
      .concepts {
        display: flex;
        flex-direction: column;
      }
    }
    .lecturas {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .lecturaWrapper {
      }
    }
    .consumos {
      .consumoWrapper {
        margin: 2rem 0;
      }
    }
  }
}

.ContractDataLine {
  display: grid;
  grid-template-columns: 15rem 1fr;
  font-size: $FONT-SIZE-MD;
  margin: 0.5rem 0;
  .name,
  .value {
    padding: 0.5rem;
  }
  .name {
    flex: 1;
    font-weight: 600;
    background-color: $COLOR-GRAY-050;
  }
  .value {
    font-weight: 400;
    border: 1px solid $COLOR-GRAY-050;
  }
}

.margenes{

margin-left: 10px;
}
