@import 'styles/Base';

.root {
  flex: 1;
  background-color: $COLOR-GRAY-900;
  color: white;
  display: flex;
  flex-direction: column;
  .errorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}
