@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 4rem;
  position: relative;
  z-index: 2;
  .header {
    align-self: center;
  }
  .months_selector {
    display: none;
    position: absolute;
    background-color: $COLOR-WHITE;
    top: 4rem;
    padding: 2rem;
    border-radius: 0.4rem;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.1);
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
  .months_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .year {
      color: $COLOR-ELECSUMGREEN;
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
    }
    .arrow_button {
      background-color: transparent;
      border: none;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
        background-color: $COLOR-ELECSUMLOW;
      }
    }
  }
  .months_grid {
    display: grid;
  }
  .monthRow {
    display: grid;
    grid-template-columns: repeat(3, 10rem);
    border-top: 1px solid $COLOR-GRAY-100;
    &:last-child {
      border-bottom: 1px solid $COLOR-GRAY-100;
    }
  }
  .month {
    background-color: $COLOR-WHITE;
    border: none;
    padding: 1.5rem 2rem;
    font-weight: 400;
    border-left: 1px solid $COLOR-GRAY-100;
    color: $COLOR-GRAY-500;
    &:last-child {
      border-right: 1px solid $COLOR-GRAY-100;
    }
    &:hover {
      color: $COLOR-ELECSUMGREEN;
      background-color: $COLOR-GRAY-000;
    }
  }
}
