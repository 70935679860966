@import 'styles/Base';

.root {
  margin: 2rem 0;
  .form {
    background-color: $COLOR-GRAY-000;
    padding: 2rem;
    .fields_container {
      display: grid;
      grid-template-columns: 40% 45%;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 2rem;
      .fields_group {
        & > * {
          margin: 1.5rem 0;
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      & > * {
        margin: 0 2rem;
      }
    }
  }
}

.EnvironmentalNews {
  .table-news {
    padding-top: 2.75rem;
    margin-bottom: 4rem;
    border-top: 1px solid rgba($COLOR-BLACK, 0.3);
    .DataTable {
      .table {
        .t-row {
          .t-content:nth-child(2),
          .t-content:nth-child(4) {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 115px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
