@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import 'styles/Base';

.concepto {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  .conceptoInfo {
    flex: 1;
    color: $COLOR-GRAY-500;
    font-weight: 400;
  }
  .conceptoValor {
    color: $COLOR-BLACK;

    letter-spacing: 0.4px;
    font-weight: 500;
  }
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 300px;
  height: 400px;
  width: 100%;
  margin-top: -20px;
}

.sliderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 350px;
}

.sliderFeature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 1rem;
  color: #fff;
  margin: 1rem 0;
}

.sliderBtnRight,
.sliderBtnLeft {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}
.estyloButtonLEFT:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}

.estyloButtonLEFT {
  height: 200px;
  background: TRANSPARENT;

  border: 0;
  width: 6%;
  // filter: invert(100%);
  fill: rgb(152, 200, 50);
}
