@import 'styles/Base';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  .left_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-weight: 600;
      margin-right: 2rem;
      font-size: $FONT-SIZE-XL;
    }
    .search_wrapper {
      width: 35rem;
    }
  }
  .filters {
    align-items: center;
    display: flex;
    gap: 2rem;
    flex-direction: row;
  }
}
