nav {
  border-right: 0 !important;
}

.root {
  margin-top: 2rem;
  padding-right: 10%;
  .flex {
    display: flex;
    div {
      margin-bottom: 0px;
    }
  }
  .DocumentsIcon {
    margin-right: 1rem;
  }

  .containerForms {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
    gap: 2rem;
    .containerForm {
      display: grid;
      grid-template-columns: minmax(30rem, 1fr);
      row-gap: 1.5rem;
    }
  }
  .posotionradio {
    flex: 1 1;
    display: grid;
    grid-template-columns: 1fr minmax(20rem, 35rem);
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin-right: 2rem;
  }
  .postflex {
    display: flex;
  }
  .postflexbutton {
    display: flex;
    align-items: flex-end;
    .buttonSecondary {
      background-color: #d4c12a;
    }

    button {
      margin-left: 10px;
      border-radius: 20px;
    }
  }
  .divBoton {
    margin-top: 20px;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  .modaldialogcentered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  .modaldialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;

    top: 40%;

    transform: 'translate(-50%, -50%)';
  }
  .modalcontent {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .modalheader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .modalbody {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .modalfooter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  @media (min-width: 576px) {
    .modaldialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
  .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

.containerFormsTest {
  display: grid;
  grid-template-columns: auto 55%;
  grid-template-rows: 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: 100vh;
  margin-top: 20px;
}
.containerFormsTestHeight {
  display: grid;
  // grid-template-columns: 45%;
  grid-template-rows: 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.containerFormsTest1 {
  width: 80%;
  grid-area: 1 / 1 / 2 / 2;
  div {
    label {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 107%;
    }
  }
}
.containerFormsTest2 {
  grid-area: 1 / 2 / 2 / 3;
  // width: 80%;
}
.direccioncolum {
  display: flex;
  flex-direction: column;
}
.imagenChange {
  max-height: 400px;
}
.inputImagen {
  width: 80%;
  border: 1px solid #dddddd;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 300;
  color: #666666;
  border-radius: 0.2rem;
  margin: 10px;
}
.closebtnImagen {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.estlobasico {
  padding-left: 20px;
}
.centerImagen {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.styleDivgeneral {
  height: 100vh;
  max-width: 100%;
  border: 1px solid #ddd;
}

.imagenMosttrar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  border: 1px solid #ddd;
}

.imagenThumnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 10px; /* Some padding */
  width: 150px; /* Set a small width */
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Add a hover effect (blue shadow) */
.imagenThumnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.test {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.imagenButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.paddingTag {
  padding-right: 15px;
}
.SectionPotencias {
  margin-bottom: 0 !important;
}
.paddingCheckbox {
  padding-right: 15px;
  input {
    margin-right: 5px;
  }
}
.SectionTarfa {
  margin-top: 15px;
  margin-bottom: 0 !important;
  font-size: 1.5rem !important;
}
.containerPotencia {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

  justify-items: flex-end;
  div {
    width: 80%;
  }
}
.containerMargenes {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

  justify-items: flex-end;
  div {
    width: 85%;
  }
}
.containerPotenciapRECIO {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: 100%;

  justify-items: flex-end;
  div {
    width: 100%;
  }
  label {
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.15px;
    font-size: 1.5rem !important;
    width: 100% !important;
  }
  input {
    margin-left: 7%;
    width: 93%;
  }
  margin-top: 20px;
}
.containerFormsImagen {
  margin-top: 20px;
  margin-bottom: 50px;
}

.botonGuardar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.positionValidar {
  display: flex;
  justify-content: flex-end;
}

.ResultadosDocumentos {
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
  & > * {
    margin: 0 1rem;
  }
}
.fields > * {
  margin: 2rem 0;
}
.margenesAnothergrid {
  div {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .containerPotenciapRECIO {
    grid-template-columns: 75%;
  }
}
.imagenDatatable {
  img {
    width: 100px !important;
    height: 80px !important;
  }
}

.MargenTopCheckbox {
  margin-top: 15px;
  margin-bottom: 25px;
}
.linea {
  border-top: 1px solid black;
  height: 2px;
  padding: 0;
  margin: 50px auto 0 auto;
}

.containerFormsleft {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
  gap: 2rem;
  .containerForm {
    display: grid;
    grid-template-columns: minmax(30rem, 1fr);
    row-gap: 1.5rem;
  }
}

.eliminarInscriot {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonDownload {
  display: block;
  margin-left: auto;
}
