@import 'styles/Base';

.contracts_container {
  max-width: 60rem;
  margin: $LAYOUT-CONTENT-PADDING;
  background-color: $COLOR-WHITE;
  .contract {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    justify-content: space-between;
    &:hover {
      @include hover_clear_shadow();
      cursor: pointer;
    }
    .name {
      padding: 0;
      margin: 0;
    }
  }
}
