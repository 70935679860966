@import 'styles/Base';

.root {
  display: grid;
  grid-template-columns: $sidebar-width 1fr;
  grid-template-rows: 100%;
  overflow-x: hidden;
  flex: 1;
  .main {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: $COLOR-GRAY-000;
  }
  .sidenav {
    display: flex;
    flex-direction: column;
  }
}
