@import 'styles/Base';

.customInput {
  border-bottom: none;
  &:focus {
    border: none;
  }
}

.root {
  position: relative;

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid $COLOR-ELECSUMGREEN;
    .prepend {
      padding: 0 1rem;
    }
    .inputWrapper {
      flex: 1;
    }
  }
  .search-results {
    position: absolute;
    background-color: white;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba($COLOR-BLACK, 0.1);
    border: solid 1px $COLOR-GRAY-100;
    .loading {
      text-align: center;
      padding: 2rem;
    }
    .notfound {
      text-align: center;
      padding: 2rem;
    }
    ul {
      list-style: none;
      margin-bottom: 0;
      li {
        border-bottom: solid 1px $COLOR-GRAY-100;
        padding: 1rem 2rem;
        &:last-child {
          border: 0;
        }
      }
    }
  }
}
