#root .SingleDatePicker_picker {
  left: 10%;
}
#root .DateInput_fang {
  left: 10% !important;
}

#root .SingleDatePicker .DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
#root .DayPicker .SingleDatePickerInput {
  border: none;
  width: 100%;
  display: flex;
}
#root .SingleDatePicker {
  width: 100%;
  position: relative;
  display: inline-block;
}

#root .DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
  width: 100%;
}

#root .DayPicker .DateInput_input {
  text-align: left;
}
