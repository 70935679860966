@import 'styles/Base';

.root {
  box-shadow: 0 0px 6px 0 rgba($COLOR-BLACK, 0.1);
  &.noshadow {
    box-shadow: none;
  }
  border-radius: 0.4rem;
  background-color: $COLOR-WHITE;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;

  &.full_height {
    height: 100%;
  }

  .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .info {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: $FONT-SIZE-XL;
          align-self: flex-start;
          font-weight: 500;
          height: 100%;
        }
        .subtitle {
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: 0.25px;
          font-size: $FONT-SIZE-MD;
          color: $COLOR-GRAY-500;
        }
      }
      .rightCorner {
        font-weight: 500;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer {
    background-color: $COLOR-WHITE;
    border: 0;
    border-top: solid 1px $COLOR-GRAY-100;
    border-radius: 0;
  }
}

.paginator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .badge-pill {
    border-radius: 50%;
    background-color: $COLOR-ELECSUMGREEN;
    margin-left: 0.3rem;
    padding: 4px;
    height: 1px;
    width: 1px;
    opacity: 0.4;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      opacity: 1;
    }
    &:hover {
      transform: scale(1.5);
    }
  }
}
