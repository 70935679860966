// Core
@import 'Responsive';
@import 'Colors';
@import 'Fonts';
@import 'Variables';
@import 'Utils';
@import 'Typography';

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: $FONT-SIZE-LG;
}
a {
  text-decoration: none;
}
* {
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

button {
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.react-colorful {
  margin-bottom: 1rem;
}
