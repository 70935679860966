@import 'styles/Base';

.toggle {
  position: relative;
  width: 80px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;

  .toggleText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .toggleSwitch {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;

    .togglHandle {
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      background-color: $COLOR-ELECSUMGREEN;;
      border-radius: 50%;
      transition: transform 0.2s ease-in-out;
    }
  }

  &.active {
    background-color: $COLOR-ELECSUMGREEN;;

    .toggleSwitch {
      transform: translateX(50px);

      .toggleHandle {
        transform: translateX(22px);
      }
    }
  }
}
