@import 'styles/Base';

.root {
  margin: 2rem 0;
  .flex {
    display: flex;
  }
  .DocumentsIcon {
    margin-right: 1rem;
  }
  .form {
    padding: 2rem;
    margin-bottom: 2rem;
    gap: 20px;
    display: flex;
    flex-direction: column;
    .fields_container {
      display: grid;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 2rem;
      //grid-template-columns: repeat(3, 35rem);
      grid-template-columns: repeat(auto-fit, minmax(20rem, 30rem));

      .fields_group {
        & > * {
          margin: 1.5rem 0;
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      & > * {
        margin: 0 2rem;
      }
    }
  }
}

.errorMessage {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  border: 1px solid #cc0000;
  border-radius: 4px;
  font-weight: bold;
}
.errorMessageLoad {
  color: #cc0000;
  padding: 2px;
  font-weight: bold;
}

.tablenews {
  padding-top: 5rem;
  margin-bottom: 4rem;
  border-top: 1px solid rgba($COLOR-BLACK, 0.3);
  td:nth-child(2) {
    text-align: left !important;
  }
  .DataTable {
    .table {
      .t-row {
        .t-content:nth-child(2) {
          text-align: left;
        }
        .t-content:nth-child(2),
        .t-content:nth-child(4) {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 115px;
          white-space: nowrap;
        }
      }
    }
  }
}

.datosPerfilConsumo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  width: fit-content;
  font-weight: 800;
  .año {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .consumoAnual {
    color: #555555;
    font-size: 14px;
  }
}
