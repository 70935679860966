@import 'styles/Base';

.body_container {
  position: relative;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 1rem 0;
    .value {
      font-size: $FONT-SIZE-XL;
      font-weight: 500;
      color: $COLOR-GRAY-500;
      line-height: 1;
    }
    .unit {
      line-height: 1;
    }
  }
  .chart {
  }
  .divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    .value {
      font-size: $FONT-SIZE-LG;
      font-weight: 500;
      color: $COLOR-GRAY-500;
      line-height: 1;
    }
    .unit {
      font-size: $FONT-SIZE-SM;
      line-height: 1;
    }
  }

  .guarantee {
    margin-top: 1rem;
    text-align: center;
    color: $COLOR-GRAY-500;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $FONT-SIZE-MD;
  }
}
.footer {
  background-color: $COLOR-ELECSUMLOW;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 1rem;
  .text_container {
    font-size: $FONT-SIZE-MD;
    padding-left: 1rem;
    color: $COLOR-GRAY-500;
  }
}
