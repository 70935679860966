@import 'styles/Base';

.root {
  padding: 2rem;

  .section_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 35rem));
    gap: 2rem;
  }
}
