@import 'styles/Base';

$BG_DEBUG: $COLOR-TRANSPARENT;
.container {
  padding: $LAYOUT-CONTENT-PADDING;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  overflow: hidden;
  gap: 2rem;
  grid-auto-flow: dense;
  .energy_fv_widget {
    grid-column: span 3;
    // grid-row: span 7;
    background-color: $BG_DEBUG;
  }

  .energy_widget {
    grid-column: span 3;
    background-color: $BG_DEBUG;
    // grid-row: span 2;
    &.expand {
      // grid-row: span 7;
    }
  }
  .saving_widget {
    grid-column: span 3;
    // grid-row: span 5;
    background-color: $BG_DEBUG;
  }
  .saving_agregate_widget {
    grid-column: span 3;
    background-color: $BG_DEBUG;
    // grid-row: span 5;
  }

  .amortization_widget {
    grid-column: span 3;
    // grid-row: span 3;
    background-color: $BG_DEBUG;
  }
  .sustainability_widget {
    grid-column: span 3;
    // grid-row: span 4;
    background-color: $BG_DEBUG;
  }
  .shared_widget {
    grid-column: span 6;
    // grid-row: span 3;
    background-color: $BG_DEBUG;
  }
  .summary_widget {
    background-color: $BG_DEBUG;
    grid-column: span 3;
    /* grid-row: span 3;
    &.expand_mid {
      grid-row: span 5;
    }

    &.expand_extra {
      grid-row: span 6;
    }
    &.expand_big {
      grid-row: span 4;
    } */
  }
}
.spinnerGeneric {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
