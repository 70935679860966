@import 'styles/Base';

.root {
  align-items: center;
  background-color: $COLOR-GRAY-000;
  border-radius: 0.2rem;
  border: 1px solid $COLOR-GRAY-100;
  color: $COLOR-GRAY-500;
  display: flex;
  flex-direction: row;
  font-size: $FONT-SIZE-MD;
  font-weight: 200;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 1.6rem;
  padding: 1.25rem 1rem;
  resize: none;
  width: 100%;
  &:focus {
    outline: none;
  }
}
