@import 'styles/Base';

.GuaranteeEvolution {
  .text {
    font-size: $FONT-SIZE-MD;
    color: $COLOR-GRAY-350;
  }
  .text-bold {
    font-size: $FONT-SIZE-MD;
    color: $COLOR-GRAY-500;
  }
}
