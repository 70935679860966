@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  .double {
  }
  .left_widget {
    margin-right: 2rem;
  }
}

.list_item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: $FONT-SIZE-XXXL;
  color: $COLOR-GRAY-350;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  .charts_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .circles_wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    .circle_info {
      display: flex;
      flex-direction: column;
      width: 80%;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      .type_container {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        .dot {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
        .text {
          padding: 0;
          margin: 0;
          font-size: $FONT-SIZE-MD;
          line-height: 1.33;
          letter-spacing: 0.4px;
          color: $COLOR-GRAY-500;
        }
      }
      .value_container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .data {
          color: $COLOR-GRAY-500;
          font-weight: 500;
          font-size: $FONT-SIZE-XL;
          line-height: 1.8rem;
          margin-bottom: 0;
          letter-spacing: 0.15px;
        }
        .unit {
          margin-left: 1rem;
          font-size: $FONT-SIZE-MD;
          letter-spacing: 0.1px;
          line-height: 1.8rem;
          color: $COLOR-GRAY-350;
          min-width: 35px;
        }
      }
    }
  }
}
