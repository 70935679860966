@import 'styles/Base';

.content {
  padding: $LAYOUT-CONTENT-PADDING;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
  grid-auto-rows: 10rem;
  gap: 2rem;
  grid-auto-flow: dense;

  .grid_e {
    height: 100%;
    grid-area: e;
    align-self: flex-end;
  }
}

.menuForm {
  padding: 1rem 1.5rem;
  background-color: $COLOR-WHITE;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  .options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-end;
  }
  .actions {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    .btn {
      height: 4.2rem;
    }
  }
  .powers_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    & > * {
      max-width: 12rem;
    }
  }
  .inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.containerMarges {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.containerO {
  position: relative;
  bottom: 15px;
}

.btnFinal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
}

.title {
  font-size: $FONT-SIZE-MD;
  color: $COLOR-BLACK;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.menuIndexado {
  padding: 1rem 1.5rem;
  background-color: $COLOR-WHITE;
  box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.120766);
  display: flex;
  flex-direction: row;
  gap: 2rem;
  .grouping {
    width: 100%;
    max-width: 15rem;
  }
  .types {
    width: 30%;
    max-width: 15rem;
  }
}
.chartWrapper {
  flex: 1;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
}

.loading_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Cargar imagen

.containerFormsTestHeight {
  display: grid;
  // grid-template-columns: 45%;
  grid-template-rows: 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.containerForms {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
  gap: 2rem;
  .containerForm {
    display: grid;
    grid-template-columns: minmax(30rem, 1fr);
    row-gap: 1.5rem;
  }
}

.imagenDatatable {
  img {
    width: 100px !important;
    height: 80px !important;
  }
}

.imagenButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.formImages {
  background-color: $COLOR-GRAY-000;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: row;
  .fields {
    flex: 1;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

// Checkbox

.type {
  font-weight: bold;
  font-size: $FONT-SIZE-XS;
  color: $COLOR-BLACK;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.checkBoxes {
  display: flex;
  flex-direction: row;
}
.form-group.FormGroup {
  margin-top: 0.5rem;
}
.btnPrincipal {
  position: absolute;
  bottom: 20%;
  right: 1.5rem;
  height: 4.2rem;
}
