@import 'styles/Base';

.root {
  margin: 2rem 0;
  .flex{
    display: flex;

  }
  .DocumentsIcon {
    margin-right: 1rem;
  }
  .form {
    padding: 2rem;
    margin-bottom: 2rem;
    .fields_container {
      display:  grid;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 2rem;
      //grid-template-columns: repeat(3, 35rem);
      grid-template-columns: repeat(auto-fit, minmax(20rem, 30rem));




      .fields_group {
        & > * {
          margin: 1.5rem 0;
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      & > * {
        margin: 0 2rem;
      }
    }
  }
}


  .tablenews{
    padding-top: 5rem;
    margin-bottom: 4rem;
    border-top: 1px solid rgba($COLOR-BLACK, 0.3);
    td:nth-child(2){
      text-align: left !important;
    }
    .DataTable {
      .table {
        .t-row {
          .t-content:nth-child(2){
            text-align: left;

          }
          .t-content:nth-child(2),
          .t-content:nth-child(4) {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 115px;
            white-space: nowrap;
          }
        }
      }
    }
  }

