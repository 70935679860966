@import 'styles/Base';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .chartWrapper {
    flex: 1;
  }
  .cumulated {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: $FONT-SIZE-MD;
    color: $COLOR-GRAY-500;
    .value {
      font-weight: 500;
      font-size: 1.6rem;
      text-align: right;
      color: black;
    }
  }
}
