@import 'styles/Base';

$tag-font-size-sm: 1.4rem;
$tag-font-size-md: 1.6rem;
$tag-font-size-lg: 1.8rem;

$tag-font-weight-normal: 400;
$tag-font-weight-strong: 600;

$tag-pd-vertical: 0.2rem;
$tag-pd-horizontal: 0.8rem;

.tag {
  font-weight: tag-font-weight-normal;
  padding: $tag-pd-vertical $tag-pd-horizontal;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-style: solid;
  text-align: center;
  width: fit-content;
  &.strong {
    font-weight: $tag-font-weight-strong;
  }
  &.block {
    width: 100%;
  }
  &--size {
    &-sm {
      font-size: $tag-font-size-sm;
    }
    &-md {
      font-size: $tag-font-size-md;
    }
    &-lg {
      font-size: $tag-font-size-lg;
    }
  }
}
