@import 'styles/Base';

.content {
  padding: $LAYOUT-CONTENT-PADDING;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
  grid-auto-rows: 10rem;
  gap: 2rem;
  grid-auto-flow: dense;
  .eu_widget {
    grid-column: span 3;
    grid-row: span 4;
  }
  .sustainability_widget {
    grid-column: span 3;
    grid-row: span 2;
  }
  .contribution_widget {
    grid-column: span 3;
    grid-row: span 2;
  }
  .emisions_widget {
    grid-column: span 3;
    grid-row: span 2;
  }
  .egreen_widget {
    grid-column: span 3;
    grid-row: span 4;
  }
  .grid_e {
    height: 100%;
    grid-area: e;
    align-self: flex-end;
  }
}

.loading_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
