@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $COLOR-GRAY-100;
  border-radius: 0.2rem;
  background-color: transparent;
  padding: 0 1rem;
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
  select {
    background-color: transparent;
    color: $COLOR-GRAY-500;
    border: none;
    display: flex;
    flex-direction: row;
    font-size: $FONT-SIZE-MD;
    font-weight: 200;
    justify-content: space-between;
    letter-spacing: 0.44px;
    padding: 1.25rem 1rem;
    width: 100%;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
}
