@import 'styles/Base';

.concepto {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  .conceptoInfo {
    flex: 1;
    color: $COLOR-GRAY-500;
    font-weight: 400;
  }
  .conceptoValor {
    color: $COLOR-BLACK;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
}
.divider {
  width: 90%;
  height: 1px;
  background-color: $COLOR-GRAY-100;
  align-self: center;
  justify-self: center;
  margin: 1.5rem auto;
}

.terminosTable {
  margin-top: 1rem;
  width: 100%;
  thead {
    tr {
      th {
        text-align: right;
        color: $COLOR-GRAY-500;
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 1rem 0;
        .period {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $COLOR-GRAY-500;
        }
        &.term_col {
          text-align: right;
          font-weight: 500;
        }
        .dot {
          height: 1rem;
          width: 1rem;
          border-radius: 8px;
          margin-right: 1rem;
        }
      }
    }
  }
}

/* .root {
  display: flex;
  flex-direction: column;
  .potencies_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
  .energies_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .energy {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: $FONT-SIZE-MD;
        margin-top: 1rem;
        text-align: center;
        font-weight: 400;
        color: $COLOR-GRAY-500;
      }
    }
  }
  .installations_title {
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 2.4rem;
    color: $COLOR-GRAY-500;
  }
  .installations_container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    .installation_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          margin: 0 2rem;
          color: $COLOR-GRAY-500;
          font-size: $FONT-SIZE-MD;
        }
      }
      .right {
        font-weight: 500;
      }
    }
  }

  .divider {
    width: 90%;
    height: 1px;
    background-color: $COLOR-GRAY-100;
    align-self: center;
    justify-self: center;
    margin: 1.5rem auto;
  }
}

.linia_potencia_root {
  max-width: 14rem;
  width: 100%;
  &:nth-child(even) {
    align-self: flex-end;
    justify-self: flex-end;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0rem;
  .dot {
    height: 1rem;
    width: 1rem;
    border-radius: 8px;
    margin-right: 1rem;
  }
  .label {
    margin-right: 1rem;
    color: $COLOR-GRAY-500;
  }
  .value {
    flex: 1;
    text-align: right;
    font-weight: 500;
  }
}
 */
