.root {
    margin-top: 2rem;
    .flex {
      display: flex;
    }
    .DocumentsIcon {
      margin-right: 1rem;
    }

    .containerForms {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
      gap: 2rem;
      .containerForm {
        display: grid;
        grid-template-columns: minmax(30rem, 1fr);
        row-gap: 1.5rem;
      }
    }
    .posotionradio {
      display: flex;
    }
    .divBoton {
      margin-top: 20px;
    }
  }

  .labelDatepicker {
    width: 100%;
    font-weight: 500;
    font-size: 1.2rem;
    color: #666666;
    margin-bottom: 0.4rem;
  }

  .inputDatepicker {
    width: 100%;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: 300;
    border-radius: 0.2rem;
    input {
      color: #666666 !important;
    }
  }

  .botonDetailForm {
    padding-bottom: 20px;
    right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2rem 0;
    position: relative;
    bottom: 0;
    /* position: fixed; */
    /* bottom: 0; */
    /* right: 0; */
    position: absolute;

    bottom: -30%;
  }

  @media (max-width: 1920px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: 0px;
      padding-bottom: 20px;
      right: 9vw;
      padding-top: 24px;
      div {
        display: flex;
      }
    }
  }
  @media (min-width: 1921px) and (max-width: 2560px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -20%;
      padding-bottom: 20px;
      right: 20vw;
      padding-top: 24px;
      div {
        display: flex;
      }
    }
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -20%;
      right: 4vw;
      padding-bottom: 20px;
      div {
        display: flex;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1285px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -70%;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }
  .margenesLefst {
    div {
      margin-bottom: 1.5rem;
    }
  }
  @media (min-width: 1286px) and (max-width: 1439px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -20%;
      padding-bottom: 20px;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }
  .margenesLefst {
    div {
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 1024px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -60%;
      padding-bottom: 10px;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }

  @media only screen and (max-device-width: 480px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -110px;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }
  @media only screen and (device-width: 768px) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -110px;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }
  /* different techniques for iPad screening */
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -70%;
      padding-bottom: 10px;
      right: 4vw;
      div {
        display: flex;
      }
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .positionBotonoes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: -70%;
      padding-bottom: 10px;

      right: 4vw;
      div {
        display: flex;
      }
    }
  }
