@import 'styles/Base';

.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  .maximeter_wrapper {
    display: grid;
    grid-template-columns: 6rem 1fr;
    gap: 1rem;
    align-items: center;
    .icon_wrapper {
      display: none;
      align-items: center;
    }
    .period_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        box-shadow: 0rem 0rem 1rem rgba($COLOR-ERROR-SHADOW, 0.5);
        background-color: rgba($COLOR-ERROR, 0.8);
        color: $COLOR-WHITE;
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        font-size: $FONT-SIZE-MD;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }
    }
    .maximeter_info {
      .value {
        font-size: $FONT-SIZE-XXL;
        margin-bottom: 0.5rem;
      }
      .date {
        color: $COLOR-GRAY-500;
        font-size: $FONT-SIZE-SM;
      }
    }
  }
}
