@import 'styles/Base';

.root {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: $FONT-SIZE-XL;
  color: $COLOR-BLACK;
  font-weight: 700;
  letter-spacing: 0.15px;
}
