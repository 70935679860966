@import 'styles/Base';

.list_item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .type_container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 1rem;
    }
    .text {
      padding: 0;
      margin: 0;
      font-size: $FONT-SIZE-MD;
      line-height: 1.4rem;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
    }
  }
  .value_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.grey {
      color: $COLOR-GRAY-400;
    }
    .data {
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
      margin-bottom: 0;
      letter-spacing: 0.15px;
    }
    .unit {
      margin-left: 1rem;
      font-size: $FONT-SIZE-MD;
      line-height: 1.8rem;
      letter-spacing: 0.1px;
    }
  }
}
.title {
  font-weight: 700;
  font-size: $FONT-SIZE-XXXL;
  color: $COLOR-GRAY-350;
}

.container {
  display: flex;
  flex-direction: column;
  .divider {
    height: 1px;
    background-color: $COLOR-GRAY-350;
    margin: 2rem 0;
  }
}

.noclient_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .quote {
    font-weight: 400;
    font-size: $FONT-SIZE-MD;
    line-height: 1.6rem;
    color: $COLOR-GRAY-500;
    margin-bottom: 1rem;
  }
  .value {
    font-size: $FONT-SIZE-XL;
    color: $COLOR-BLACK;
    font-weight: 500;
  }
  .link {
    font-weight: 500;
    color: $COLOR-ELECSUMGREEN;
    font-size: $FONT-SIZE-LG;
    text-transform: uppercase;
    @include hover_clear_shadow();
    padding: 1rem;
  }
  img {
    height: 15rem;
  }
}
