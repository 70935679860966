@import 'styles/Base';

.pick-container {
  display: flex;
  flex-direction: row;
  .scale {
    flex: 1;
    display: flex;
    flex-direction: row;
    *:not(:last-child) {
      margin-right: 1.5rem;
    }
    .item {
      background-color: transparent;
      border-radius: 5px;
      border: none;
      display: inline-block;
      cursor: pointer;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      text-decoration: none;
      &:hover {
        @include hover_clear_shadow();
        color: $COLOR-ELECSUMGREEN;
      }
      &:focus {
        outline: none;
      }
      &.active {
        background-color: $COLOR-ELECSUMGREEN;
        color: $COLOR-WHITE;
        box-shadow: 0rem 0rem 1rem rgba($COLOR-BLACK, 0.1);
      }
    }
  }
}
