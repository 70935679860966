@import 'styles/Base';
.tableMatcher {
  width: 100%;
  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      border-bottom: 1px solid $COLOR-GRAY-600;
      position: sticky;
      top: -0.5rem;
      background-color: white;
      z-index: 1;

      /* Agregar este estilo */
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 1px;
        background-color: #ddd;
        display: block;
      }
      tr {
        th {
          padding: 12px 12px 12px 12px;
          //text-align: left;
          border-bottom: 1px solid #ddd; // Cambiar al color que desees
          white-space: nowrap;
          &:first-child {
            //padding-left: 12px;
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1.5px solid $COLOR-GRAY-100;
        border-top: 1.5px solid $COLOR-GRAY-100;
        &:nth-child(odd) {
          background-color: $COLOR-WHITE;
        }
        &:nth-child(even) {
          background-color: $COLOR-GRAY-000;
        }
        &:hover {
          background-color: $COLOR-ELECSUMLOW;
          cursor: pointer;
          td {
            color: $COLOR-GRAY-700;
          }
        }
        td {
          padding: 2rem 0;
          font-size: $FONT-SIZE-MD;
          font-weight: 400;
          color: $COLOR-GRAY-500;
          &:first-child {
            padding-left: 1.5rem;
          }
          &.sistemaOperativo {
          }
        }
      }
    }
  }
}
