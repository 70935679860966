@import 'styles/Base';

.root {
  margin: $LAYOUT-CONTENT-PADDING;
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
    gap: 2rem;
    align-items: flex-start;
  }
}
.notselected {
  padding-top: 10%;
  text-align: center;
}

.loading_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
