@import 'styles/Base';

.ChartDataList {
  .sickGreen {
    color: $COLOR-ELECSUMGREEN !important;
    opacity: 0.87 !important;
  }
  .red {
    color: $COLOR-ERROR !important;
    opacity: 0.87 !important;
  }
  .text-red {
    color: $COLOR-ERROR-SHADOW;
  }
}

.list_item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.type_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.value_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title {
  font-weight: 700;
  font-size: $FONT-SIZE-XXXL;
  color: $COLOR-GRAY-350;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 1rem;
}
.text {
  padding: 0;
  margin: 0;
  font-size: $FONT-SIZE-LG;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: $COLOR-GRAY-500;
}
.data {
  color: $COLOR-GRAY-500;
  font-weight: 500;
  font-size: $FONT-SIZE-XL;
  margin-bottom: 0;
  letter-spacing: 0.15px;
}
.unit {
  margin-left: 1rem;
  font-size: $FONT-SIZE-MD;
  letter-spacing: 0.1px;
  color: $COLOR-GRAY-350;
  width: 35px;
}
