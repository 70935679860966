@import 'styles/Base';
.root {
  .tooltip {
  }
}
.card_body {
}
.chart_wrapper {
  height: 50rem;
}
.picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  .button {
    background-color: $COLOR-TRANSPARENT;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    &:disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:disabled) {
        @include hover_clear_shadow();
      }
    }
  }
  .value {
    margin: 0 2.5rem;
    color: $COLOR-ELECSUMGREEN;
  }
}
