@import 'styles/Base';




.DayPicker {
  display: flex;
  flex-direction: column;
  align-items: center;

.CalendarDay {
    font-size: $FONT-SIZE-MD;
  }

  .CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: $COLOR-GRAY-400;
  }

  // REVIEWED

  .CalendarDay__selected {
    background-color: $COLOR-ELECSUMGREEN;
    border: 2px solid darken($COLOR-ELECSUMGREEN, 5); //default styles include a border
    color: white;
  }

  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    background: $COLOR-ORANGE-STRONG;
    border: 2px solid darken($COLOR-ORANGE-STRONG, 5); //default styles include a border
    color: white;
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: $COLOR-ELECSUMLOW;
    border: 1px solid $COLOR-GRAY-100;
    color: $COLOR-GRAY-800;
  }

  .CalendarMonth_caption {
    text-transform: capitalize;
    color: $COLOR-ELECSUMGREEN;
    strong {
      font-weight: 500;
    }
  }
  // Dias dentro del intervalo
  .CalendarDay__selected_span {
    background-color: $COLOR-ELECSUMLOW;
    color: $COLOR-GRAY-800;
    border: 1px solid $COLOR-GRAY-100;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: $COLOR-GRAY-050;
    border: 1px solid $COLOR-GRAY-100;
    color: $COLOR-GRAY-700;
  }

  .DayPickerNavigation_button__default {
    border: none;
    &:hover {
      border: none;
      background-color: $COLOR-ELECSUMLOW;
    }
  }

  .DateInput_input {
    padding: 0.75rem 1rem;
    font-size: $FONT-SIZE-MD;
    text-align: center;
    font-weight: 400;
    color: $COLOR-ELECSUMGREEN;
    &:hover {
      cursor: pointer;
    }
  }

  .DateInput_input__focused {
    border-bottom-color: $COLOR-ELECSUMGREEN;
  }
  .DateRangePickerInput_arrow_svg {
    fill: $COLOR-ELECSUMGREEN;
  }
  .DayPickerNavigation_svg__horizontal {
    fill: $COLOR-ELECSUMGREEN;
  }

  .SingleDatePickerInput {
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  table tbody tr td:first-child {
    padding-left: 0rem;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    height: 45rem !important;
    }
}
