@import 'styles/Base';

$ARROW-SIZE: 0.5rem;
$MARGIN: 3rem;
$BG-COLOR: $COLOR-BLACK;
$TEXT-COLOR: $COLOR-WHITE;

.container {
  display: inline-block;
  position: relative;
  .tip {
    position: absolute;
    border-radius: 4px;
    transform: translateX(-50%);
    padding: 0.8rem;
    color: $BG-COLOR;
    background: white;
    font-size: 14px;
    border: 1px solid $BG-COLOR;
    line-height: 1;
    z-index: 100;
    min-width: 33rem;
    width: 100%;

    &::before {
      content: ' ';

      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: $ARROW-SIZE;
      margin-left: calc(#{$ARROW-SIZE} * -1);
    }
    &-top {
      top: calc(#{$MARGIN} * -1);
      &::before {
        top: 100%;
        border-top-color: $BG-COLOR;
      }
    }
    &-right {
      left: calc(100% + #{$MARGIN});
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &::before {
        left: calc(#{$ARROW-SIZE} * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: $BG-COLOR;
      }
    }
    &-bottom {
      left: 58%;
      &::before {
        bottom: 100%;
        border-bottom-color: $BG-COLOR;
      }
    }
    &-bottom-with-info {
      left: 50%;
      top: 32%;
      &::before {
        bottom: 100%;
        border-bottom-color: $BG-COLOR;
      }
    }
    &-left {
      left: auto;
      right: calc(100% + #{$MARGIN});
      top: 50%;
      transform: translateX(0) translateY(-50%);
      &::before {
        left: auto;
        right: calc(#{$ARROW-SIZE} * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: $BG-COLOR;
      }
    }
  }
}
