@import 'styles/Base';

.container {
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  padding: $LAYOUT-CONTENT-PADDING;
  margin: 0 auto;
  flex: 1;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.margenBotImagen {
  margin-bottom: 10px;
  td {
    img {
      width: 100px !important;
      height: 42px !important;
    }
  }
}
.positionBotonoes {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  div {
    display: flex;
  }
}
