@import 'styles/Base';

.flex {
  display: flex;
  margin-top: 20px;
}
.LandscapeIcon {
  margin-right: 1rem;
}
.form {
  background-color: $COLOR-GRAY-000;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: row;
  .fields {
    flex: 1;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
