@import 'styles/Base';

.root {
  .menu {
    padding: 1rem 1.5rem;
    background-color: $COLOR-WHITE;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.120766);
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap: 2rem;
    align-items: center;
  }

  .loading_wrapper,
  .nodata_wrapper {
    padding: 5%;
    .messages {
      text-align: center;
      color: $COLOR-GREY-DARK;
      margin-bottom: $SPACING-XL;
      .title {
        font-size: $FONT-SIZE-XL;
        font-weight: 500;
        margin-bottom: $SPACING-MD;
      }
      .subtitle {
      }
    }
  }
  .sim_wrapper {
    padding: $LAYOUT-CONTENT-PADDING;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
    gap: 2rem;
    .summary {
      margin-bottom: 0;
      grid-column: span 12;
      .concepts {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
        align-items: flex-end;
        .concept {
          color: $COLOR-BLACK;
          .label {
            font-size: $FONT-SIZE-LG;
            font-weight: 500;
            line-height: 2.4rem;
            letter-spacing: 0.1px;
          }
          .value {
            font-size: $FONT-SIZE-MD;
            font-weight: 400;
          }
        }
        .total {
          grid-column: span 2;
          justify-self: flex-end;
          padding: 0 2rem;
          .label {
            font-size: $FONT-SIZE-LG;
            font-weight: 500;
            text-align: right;
            text-transform: uppercase;
            line-height: 2.4rem;
            letter-spacing: 0.1px;
          }
          .value {
            font-size: $FONT-SIZE-XXXL;
            font-weight: 500;
          }
        }
      }
    }
    .optimization {
      grid-column: span 8;
      margin-bottom: 0;
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          tr {
            th {
              font-size: $FONT-SIZE-SM;
              text-align: left;
              line-height: 2.4rem;
              letter-spacing: 0.4px;
              padding: 1rem;
              border-bottom: 1px solid $COLOR-BLACK;
              font-weight: 400;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: $COLOR-WHITE;
            }
            &:nth-child(even) {
              background-color: $COLOR-GRAY-000;
            }
            &:hover {
              background-color: $COLOR-ELECSUMLOW;
              cursor: pointer;
              td {
                color: $COLOR-GRAY-700;
              }
            }
            td {
              font-weight: 400;
              color: $COLOR-GRAY-500;
              padding: 1.5rem 1rem;
              font-size: $FONT-SIZE-MD;
              &.p {
                font-weight: 800;
                color: $COLOR-BLACK;
                min-width: 4rem;
              }
              &.optimal {
              }
              &.actual {
              }
            }
          }
        }
      }
    }
    .saving {
      .title {
        color: $COLOR-WHITE;
      }
      margin-bottom: 0;
      grid-column: span 4;
      background: linear-gradient(108.91deg, $COLOR-GRADIENT-GREEN 56.54%, $COLOR-GRADIENT-YELLOW 107.98%);

      .concepts {
        padding: 0rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .wrapper {
          text-align: center;
          margin: 3rem 0;
          color: $COLOR-WHITE;
          display: flex;
          flex-direction: column;
          align-items: center;
          .label {
            font-size: $FONT-SIZE-MD;
            font-weight: 500;
            line-height: 2.4rem;
          }
          .value {
            margin: 1rem 0;
            font-size: $FONT-SIZE-XXXL;
            font-weight: 700;
          }
          .quote {
            font-size: $FONT-SIZE-MD;
            font-weight: 200;
            letter-spacing: 0.25px;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
