@import 'styles/Base';

nav {
  border-right: 0 !important;
}

.root {
  //amargin-top: 5rem;
  width: 100%;
  padding: 10px 32px;

  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
  .flexContainer {
    display: flex;
    align-items: baseline;
  }
  .flexContainerInforme {
    display: flex;
    align-items: baseline;
  }
  .flex {
    display: flex;
    div {
      margin-bottom: 0px;
    }
  }
  .DocumentsIcon {
    margin-right: 1rem;
  }

  .containerForms {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
    gap: 5rem;
    padding: 1.5rem;
    .containerForm {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      width: 100%;
    }
  }
  .containerGeneracio {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
  .containerGeneracioTable {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
  .betas_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    .betas_section {
      display: flex;
      gap: 2rem;
    }

    // Numbers beta
    .containerBeta {
      display: flex;
      grid-gap: 1rem;
      gap: 1rem;
      flex-direction: column;
      label {
        font-weight: 500;
        font-size: $FONT-SIZE-SM;
        margin-bottom: 0.4rem;
        color: $COLOR-GRAY-500;
      }

      input {
        align-items: center;
        background-color: transparent;
        border-radius: 0.2rem;
        border: 1px solid $COLOR-GRAY-100;
        color: $COLOR-GRAY-500;
        display: flex;
        flex-direction: row;
        font-size: $FONT-SIZE-MD;
        font-weight: 200;
        justify-content: space-between;
        padding: 1.25rem 1rem;
        &:focus {
          outline: none;
          border: 2px solid $COLOR-ELECSUMGREEN;
        }
      }
    }
    // Radio buttons
    .checkdotsContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        margin-right: 16px;

        input[type='radio'] {
          display: none;

          &:checked + .checkdot {
            background-color: $COLOR-ELECSUMGREEN;
            border-color: $COLOR-ELECSUMGREEN;
          }
        }

        .checkdot {
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 2px solid #dcdde1;
          border-radius: 50%;
          margin-right: 8px;
          transition: background-color 0.3s, border-color 0.3s;
        }
      }
    }
  }
  .posotionradio {
    flex: 1 1;
    display: grid;
    grid-template-columns: 1fr minmax(20rem, 35rem);
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin-right: 2rem;
  }
  .postflex {
    display: flex;
  }
  .postflexbutton {
    display: flex;
    align-items: flex-end;
    .buttonSecondary {
      background-color: #d4c12a;
    }

    button {
      margin-left: 10px;
      border-radius: 20px;
    }
  }
  .divBoton {
    margin-top: 20px;
  }

  .modalGrid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 10px;
    padding-bottom: 5px;
  }
  .modalGrid4Columns {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap: 10px;
    align-items: center;
    padding-bottom: 5px;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  .modaldialogcentered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  .modaldialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;

    top: 40%;

    transform: 'translate(-50%, -50%)';
  }
  .modalcontent {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .modalheader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .modalbody {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .modalfooter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  @media (min-width: 576px) {
    .modaldialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
  .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

.containerFormsTest {
  display: grid;
  grid-template-columns: auto 55%;
  grid-template-rows: 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: 100vh;
  margin-top: 20px;
}
.containerFormsTestHeight {
  display: grid;
  // grid-template-columns: 45%;
  grid-template-rows: 2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.containerFormsTest1 {
  width: 80%;
  grid-area: 1 / 1 / 2 / 2;
  div {
    label {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 30%;
    }
  }
}
.containerFormsTest2 {
  grid-area: 1 / 2 / 2 / 3;
  // width: 80%;
}
.direccioncolum {
  display: flex;
  flex-direction: column;
}
.imagenChange {
  max-height: 400px;
}
.inputImagen {
  width: 80%;
  border: 1px solid #dddddd;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 300;
  color: #666666;
  border-radius: 0.2rem;
  margin: 10px;
}
.closebtnImagen {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.estlobasico {
  padding-left: 20px;
}
.centerImagen {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.styleDivgeneral {
  height: 100vh;
  max-width: 100%;
  border: 1px solid #ddd;
}

.imagenMosttrar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  border: 1px solid #ddd;
}

.imagenThumnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 10px; /* Some padding */
  width: 150px; /* Set a small width */
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Add a hover effect (blue shadow) */
.imagenThumnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.test {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.imagenButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.paddingTag {
  padding-right: 15px;
}
.SectionPotencias {
  margin-bottom: 0 !important;
}
.paddingCheckbox {
  padding-right: 15px;
  input {
    margin-right: 5px;
  }
}
.SectionTarfa {
  margin-top: 15px;
  margin-bottom: 0 !important;
  font-size: 1.5rem !important;
}
.containerPotencia {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

  justify-items: flex-end;
  div {
    width: 80%;
  }
}
.containerPotenciapRECIO {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.  .'
    '.  .'
    '.  .';
  grid-template-columns: 100%;

  justify-items: flex-end;
  div {
    width: 100%;
  }
  label {
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.15px;
    font-size: 1.5rem !important;
    width: 100% !important;
  }
  input {
    margin-left: 7%;
    width: 93%;
  }
  margin-top: 20px;
}
.containerFormsImagen {
  margin-top: 20px;
  margin-bottom: 50px;
}

.botonGuardar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.positionValidar {
  display: flex;
  justify-content: flex-end;
}

.ResultadosDocumentos {
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
  & > * {
    margin: 0 1rem;
  }
}
.fields > * {
  margin: 2rem 0;
}
.margenesAnothergrid {
  div {
    margin-bottom: 1.5rem;
  }
}

.modalFlex {
  display: flex;
  flex-direction: column;
  gap: 5px;
  p {
    font-weight: 500;
    font-size: $FONT-SIZE-SM;
    margin-bottom: 0.4rem;
    color: $COLOR-GRAY-500;
  }
}
.modalCsv {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modalFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 100%;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.tabModal {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .containerPotenciapRECIO {
    grid-template-columns: 75%;
  }
  .containerForms {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr)) !important;
    gap: 1.5rem;
    .containerForm {
      align-items: flex-start;
      display: grid;
      grid-template-columns: minmax(30rem, 1fr);
      row-gap: 1.5rem;
    }
  }
}
.imagenDatatable {
  img {
    width: 100px !important;
    height: 80px !important;
  }
}

.MargenTopCheckbox {
  margin-top: 15px;
  margin-bottom: 25px;
}
.linea {
  border-top: 1px solid black;
  height: 2px;
  padding: 0;
  margin: 20px auto 0 auto;
}

.containerFormsleft {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
  gap: 2rem;
  .containerForm {
    display: grid;
    grid-template-columns: minmax(30rem, 1fr);
    row-gap: 1.5rem;
  }
}
.save-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.4rem;
}

.actions_wrapper {
  // max-width: 144rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .action {
    width: 15rem;
    margin-left: 2rem;
  }
}

.paddingTitle {
  width: 90%;
}

.modalTitle {
  font-size: 1.5rem !important;
  padding: 0 !important;
  p {
    font-size: 1.5rem !important;
    color: $COLOR-BLACK;
    font-weight: 700;
    letter-spacing: 0.15px;
  }
}

// Table
.table_wrapper {
  width: 100%;
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
  .nodata {
    color: $COLOR-GRAY-600;
    padding: 2rem 0;
  }
  grid-column: span 2;
}

// Checkbox Table
.checkboxTable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  text-transform: uppercase;
  color: $COLOR-GRAY-500;
  margin-bottom: 0;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    margin-left: 0;
    background-color: $COLOR-WHITE;
    margin-right: 0.5rem;
    &:focus {
      outline: none;
    }
    &:checked + span {
      color: $COLOR-BLACK;
      font-weight: 500;
    }
  }
  input[type='checkbox']::before {
    content: '\2714';
    position: absolute;
    font-size: $FONT-SIZE-MD;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type='checkbox']:checked::before {
    visibility: visible;
    background-color: $COLOR-ELECSUMGREEN;
  }
}

.informe {
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;
  width: auto;
}

.informeExcluido {
  margin-top: 2.4rem;
}

//Menu add member
.addMemberWrapper {
  margin-top: 5rem;
  display: flex;
  width: 100%;
  gap: 2rem;
}

.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toogle_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.datosPerfilConsumo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  .año {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .consumoAnual {
    color: #555555;
    font-size: 14px;
  }
}

.tituloFecha {
  margin-bottom: 1.5rem;
  padding-bottom: 0rem;
  font-size: 2rem;
  color: #000000;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.errorMessage {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  border: 1px solid #cc0000;
  border-radius: 4px;
  font-weight: bold;
}
.errorMessageLoad {
  color: #cc0000;
  padding: 2px;
  font-weight: bold;
}

.errorMessageLoadGood {
  color: $COLOR-ELECSUMGREEN;
  padding: 2px;
  font-weight: bold;
}
.errorMessage p {
  margin: 0;
}
.customToast {
  z-index: 9999999;
  /* Other custom styles for the toast */
}

.tableListaCombinaciones {
  white-space: nowrap;
}
.divToooltip {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.link_paragraph {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.link_paragraph:hover {
  color: red;
  text-decoration: none;
}

.loadingButtonSameSize {
  max-width: 200px;
  min-width: 200px;
}

.SectionTitleWithoutPadding {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.containerStep {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.containerPorfile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
  align-items: baseline;
  .perfil {
    display: flex;
    gap: 5rem;
    margin-bottom: 1rem;
    width: 100%;
    align-items: baseline;
  }
}

.collapseText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Ajusta este valor según el tamaño máximo que desees */
}
