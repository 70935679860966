@import 'styles/Base';

.root {
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  gap: 1rem;
  height: 100%;
  max-width: 50rem;
  padding: 0 2rem;
  @include not_select();
  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo {
      background-color: $COLOR-WHITE;
      height: 3.75rem;
      width: 3.75rem;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: $COLOR-WHITE;
    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 700;
      font-size: $FONT-SIZE-LG;
      letter-spacing: 1px;
      line-height: 2rem;
    }
    .subtitle {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: $FONT-SIZE-MD;
      line-height: 1.8rem;
      letter-spacing: 0.4px;
    }
    .quote {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: $FONT-SIZE-SM;
      line-height: 1.8rem;
      letter-spacing: 0.4px;
    }
  }
}
