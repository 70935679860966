@import 'styles/Base';

.root {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 5rem;
    font-weight: 800;
    margin-bottom: 3rem;
  }
  h4 {
    text-align: center;
    margin-bottom: 3rem;
  }
  code {
    color: $COLOR-ELECSUMGREEN;
  }
}
