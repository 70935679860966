// Color Palette. If new color added, must be also added inside Colors.tsx

$COLOR-ELECSUMGREEN: #98c832;
$COLOR-GRADIENT-GREEN: #9cc217;
$COLOR-ELECSUMSTRONG: #72a800;
$COLOR-ELECSUMLOW: #f5faeb;
$COLOR-GRADIENT-YELLOW: #f5e61e;

$COLOR-SUCCESS: #00e096;
$COLOR-ERROR: #d8000c;
$COLOR-ERROR-SHADOW: #7a0006;
$COLOR-NOTIFICATIONS: #ff5983;

$COLOR-ANDROID: #3ddc84;
$COLOR-BROWN: #774700;
$COLOR-EXCEDENTS: #0091ff;
$COLOR-GENERACIO: #ffbe2c;
$COLOR-ORANGE-STRONG: #fd9800;
$COLOR-YELLOW-LOW: #ffcd6b;

$COLOR-XARXA: #d8d8d8;
$COLOR-CONSUM: #666666;

$COLOR-BLUE-100: #56ccf2;
$COLOR-BLUE-200: #2f80ed;

$COLOR-GREY-LOW: #f7f7f7;
$COLOR-GREY-MEDIUM: #d8d8d8;
$COLOR-GREY-DARK: #666666;

$COLOR-WHITE: #ffffff;
$COLOR-GRAY-000: #f7f7f7;
$COLOR-GRAY-050: #eeeeee;
$COLOR-GRAY-100: #dddddd;
$COLOR-GRAY-200: #bbbbbb;
$COLOR-GRAY-250: #aaaaaa;
$COLOR-GRAY-300: #999999;
$COLOR-GRAY-350: #858585;
$COLOR-GRAY-400: #777777;
$COLOR-GRAY-500: #666666;
$COLOR-GRAY-600: #555555;
$COLOR-GRAY-700: #444444;
$COLOR-GRAY-800: #333333;
$COLOR-GRAY-900: #222222;
$COLOR-BLACK: #000000;

$COLOR-TRANSPARENT: transparent;

.green {
  color: $COLOR-ELECSUMGREEN;
}

.grey {
  color: $COLOR-XARXA;
}

.bck-orange {
  background-color: $COLOR-GENERACIO;
}

.bck-green {
  background-color: $COLOR-ELECSUMGREEN;
}

.bck-grey {
  background-color: $COLOR-XARXA;
}

.bck-blue {
  background-color: $COLOR-BLUE-200;
}

.bck-dark-grey {
  background-color: $COLOR-GRAY-500;
}

.bck-red {
  background-color: $COLOR-ERROR;
}

.bck-yellow {
  background-color: $COLOR-YELLOW-LOW;
}
