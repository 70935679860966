$BREAK-XS: 480px; // MOBILE
$BREAK-SM: 768px; // TABLET
$BREAK-MD: 1024px;
$BREAK-LG: 1200px; //DESKTOP
$BREAK-XL: 1440px;
$BREAK-XXL: 1600px;

$DEVICE-MOBILE: 576px;
$DEVICE-TABLET: 768px;
$DEVICE-DESKTOP: 992px;
$DEVICE-LARGE: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin mobile() {
  @media only screen and (max-width: $DEVICE-MOBILE) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (min-width: $DEVICE-MOBILE) and (max-width: $DEVICE-TABLET) {
    @content;
  }
}

@mixin portable() {
  @media only screen and (min-width: $DEVICE-MOBILE) and (max-width: $DEVICE-DESKTOP) {
    @content;
  }
}

@mixin laptop() {
  @media only screen and (min-width: $DEVICE-TABLET) and (max-width: $DEVICE-DESKTOP) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: $DEVICE-DESKTOP) and (max-width: $DEVICE-LARGE) {
    @content;
  }
}

@mixin retina() {
  @media only screen and (min-width: $DEVICE-LARGE) {
    @content;
  }
}

@mixin toTablet() {
  @media only screen and (max-width: $DEVICE-TABLET) {
    @content;
  }
}

@mixin tabletUp() {
  @media only screen and (min-width: $DEVICE-TABLET) {
    @content;
  }
}

@mixin toLaptop() {
  @media only screen and (max-width: $DEVICE-DESKTOP) {
    @content;
  }
}

@mixin laptopUp() {
  @media only screen and (min-width: $DEVICE-DESKTOP) {
    @content;
  }
}

@mixin laptopUpLarge() {
  @media only screen and (min-width: $DEVICE-LARGE) {
    @content;
  }
}
