@import 'styles/Base';

.container {
  // padding: $LAYOUT-CONTENT-PADDING;
  margin: 0rem;
  flex: 1;
  max-height: 100%;
  width: 100%;
  background-color: $COLOR-WHITE;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 2rem;
  }
  .centerButton {
    text-align: end;
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
  }
  .table_wrapper {
    overflow-y: scroll;
    height: 60vh;

    width: 100%;
    margin-bottom: $SPACING-SM;
    flex: 1;
    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
  }
}

.container2 {
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  padding: $LAYOUT-CONTENT-PADDING;
  margin: 0 auto;
  height: 60vh;

  flex: 1;
  max-height: 100%;
  width: 100%;
  background-color: $COLOR-WHITE;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 2rem;
  }
  .table_wrapper {
    overflow-y: scroll;
    width: 100%;
    margin-bottom: $SPACING-SM;
    flex: 1;
    height: 60vh;

    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
  }
}
.errorMessage {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  border: 1px solid #cc0000;
  border-radius: 4px;
  font-weight: bold;
}

.spaceBetween {
  justify-content: space-between;
}

.end {
  justify-content: flex-end;
  text-align: end;
}
.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Asegúrate de que el loader esté por encima de todo
}
