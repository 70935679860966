@import 'styles/Base';

.root {
  background-color: $COLOR-ELECSUMGREEN;
  padding: 2rem 0.5rem 2rem 2.5rem;
  border-radius: 4px;
  .img_wrapper {
    margin-bottom: 22px;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
  .title {
    font-size: $FONT-SIZE-SM;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $COLOR-WHITE;
  }
  .data {
    font-size: $FONT-SIZE-XXL;
    color: $COLOR-WHITE;
    .units {
      margin-left: 10px;
    }
  }
}
