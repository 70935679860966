@import 'styles/Base';

.loading_wrapper {
  padding: $LAYOUT-CONTENT-PADDING;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.root {
  padding: $LAYOUT-CONTENT-PADDING;

  .forms_wrapper {
    max-width: 120rem;
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: flex-start;
  }
  .estimation_container {
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: 2rem;
    }
  }
  .study_container {
    .paginator {
      margin: 1.5rem 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .button {
        font-size: $FONT-SIZE-LG;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .value {
        font-size: $FONT-SIZE-SM;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $COLOR-ELECSUMGREEN;
        width: 104px;
        text-align: center;
        font-size: $FONT-SIZE-LG;
        .year {
          font-size: $FONT-SIZE-LG;
          margin-left: 10px;
        }
      }
    }
  }

  .actions_wrapper {
    max-width: 144rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .action {
      width: 15rem;
      margin-left: 2rem;
    }
  }
}

.nodata {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}
