@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  .options {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    *:not(:last-child) {
      margin-right: 1.5rem;
    }
    .item {
      background-color: transparent;
      border-radius: 5px;
      border: none;
      display: inline-block;
      cursor: pointer;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      text-decoration: none;

      &:focus {
        outline: none;
      }
      &.active {
        background-color: $COLOR-ELECSUMGREEN;
        color: $COLOR-WHITE;
      }
    }
  }
  .value {
  }
  .button {
    &:active {
    }
  }
}
