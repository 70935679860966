@import 'styles/Base';

.root {
  .menu {
    padding: 1rem 1rem;
    background-color: $COLOR-WHITE;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.120766);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .export_button {
    }
  }
}
