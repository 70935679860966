@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  .spinner {
    border-radius: 50%;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: $COLOR-ELECSUMGREEN;
    animation: spin 1s ease infinite;
    height: 100%;
    width: 100%;
    opacity: 0.8;
  }
  svg {
    animation: spinLogo 1s ease infinite;
    position: absolute;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinLogo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
