@import 'styles/Base';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .formcontainer {
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.15);
    background-color: $COLOR-WHITE;

    padding: 4rem 2rem 2rem 2rem;

    .logo {
      width: 50%;
      margin: 0 auto;
      display: block;
      margin-bottom: 4rem;
    }
    .field {
      margin-bottom: 2rem;
    }

    .forgot {
      margin-top: 0.5rem;
      font-size: $FONT-SIZE-SM;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    hr {
      height: 1px;
      background-color: $COLOR-GRAY-200;
      flex: 1;
      border: none;
    }
    span {
      color: $COLOR-GRAY-500;
      margin: 0 2rem;
      font-size: $FONT-SIZE-MD;
    }
  }
  .submit_button {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    .btn {
      width: 100%;
    }
  }

  .registerButton {
    align-self: center;
  }
}
.error {
  font-size: $FONT-SIZE-SM;
  color: $COLOR-ERROR;
  line-height: 1.4rem;
}
