@import 'styles/Base';

.wrapperClass {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editorClass {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbarClass {
  border: 1px solid #ccc;
}

.preview {
  padding: 1rem;
  margin-top: 1rem;
}

img {
  max-width: inherit !important;
  max-height: inherit !important;
  border-radius: inherit !important;
  margin-top: inherit !important;
}
