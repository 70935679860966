@import 'styles/Base';

.root {
}
.picker_button {
  color: $COLOR-ELECSUMGREEN;
  background-color: transparent;
  font-weight: 400;
}
.calendarStyle {
  margin-top: 4rem;
  transform: scale(1.55);
}

.loading_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
