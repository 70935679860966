@import 'styles/Base';

.root {
  align-items: center;
  border: 0;
  display: flex;
  flex-direction: row;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  justify-content: space-around;
  letter-spacing: 1.35px;
  padding: 1rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  @include not_select();
  &.block {
    display: block;
    width: 100%;
  }
  svg {
    margin-right: 1rem;
  }
  &:hover {
    box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.1);
    transform: translateY(-0.1rem);
    cursor: pointer;
  }

  // VARIANTS
  &.primary {
    background-color: $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    &:hover {
      background-color: darken($COLOR-ELECSUMGREEN, 5);
    }
    &:active,
    &:focus {
      background-color: $COLOR-ELECSUMSTRONG;
      border-color: $COLOR-XARXA;
      outline: 0;
      box-shadow: none;
    }
    &:disabled {
      background-color: rgba($COLOR-ELECSUMGREEN, 0.5);
    }
  }
  &.secondary {
    color: $COLOR-ELECSUMGREEN;
    background-color: transparent;
    &:hover {
      background-color: rgba($COLOR-ELECSUMLOW, 0.75);
    }
    &:active {
      background-color: $COLOR-ELECSUMLOW;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &.tertiary {
    color: $COLOR-ELECSUMGREEN;
    background-color: $COLOR-WHITE;
    border: 2px solid $COLOR-ELECSUMGREEN;
    &:active,
    &:hover {
      background-color: rgba($COLOR-ELECSUMLOW, 0.95);
    }
    &:active {
      transform: scale(1.02);
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  &.quaternary {
    color: $COLOR-EXCEDENTS;
    background-color: transparent;
    border: 2px solid $COLOR-EXCEDENTS;
    &:active,
    &:hover {
      background-color: rgba($COLOR-EXCEDENTS, 0.05);
    }
    &:active {
      transform: scale(1.02);
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  &.reddelete {
    background-color: $COLOR-ERROR;
    color: $COLOR-WHITE;
    &:hover {
      background-color: darken($COLOR-ERROR, 5);
    }
    &:active,
    &:focus {
      background-color: $COLOR-ERROR;
      border-color: $COLOR-ERROR;
      outline: 0;
      box-shadow: none;
    }
    &:disabled {
      background-color: rgba($COLOR-ERROR, 0.5);
    }
  }
}
