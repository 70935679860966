@import 'styles/Base';

.table_wrapper {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto; // Añade scroll horizontal en caso de muchas columnas

  thead {
    tr {
      th {
        background-color: $COLOR-WHITE;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 1rem; // Ajuste de padding para más espacio
        text-align: left;
        font-size: $FONT-SIZE-MD;
        font-weight: 700;
        color: black;
        text-transform: uppercase;
        border-bottom: 2px solid #ddd; // Añadir borde inferior para separar encabezado

        &:first-child {
          padding-left: 1.5rem;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem; // Ajuste de padding en celdas
        border-bottom: 1px solid #ddd; // Borde inferior para separar filas
        font-size: $FONT-SIZE-SM; // Tamaño de fuente más pequeño para datos
        text-align: left;

        &:first-child {
          padding-left: 1.5rem;
        }
      }
    }
  }

  .nodata {
    text-align: center;
    width: 100%;
    padding: 2rem;
    color: #666; // Color gris para texto "sin datos"
    font-size: $FONT-SIZE-MD;
    font-weight: 600; // Hacer que el texto sea más visible
  }
}
