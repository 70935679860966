@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  .label {
    color: $COLOR-GRAY-400;
    font-size: $FONT-SIZE-SM;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  .button {
    padding: 0.25rem 1rem;
    border: 1px solid $COLOR-GRAY-100;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    span {
      font-size: $FONT-SIZE-MD;
      font-weight: 200;
      padding: 1rem;
      color: $COLOR-GRAY-500;
    }
    svg {
      margin: 0 0.5rem;
    }
  }
  ul {
    box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
    list-style: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $COLOR-WHITE;
    top: 6rem;
    width: 100%;
    overflow-y: scroll;
    z-index: 5;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &.hide {
      display: none;
    }
    li {
      font-size: $FONT-SIZE-MD;
      font-weight: 400;
      &.active {
        font-weight: 600;
        background-color: $COLOR-ELECSUMLOW;
      }
      &:hover {
        cursor: pointer;
        background-color: $COLOR-ELECSUMLOW;
      }
      padding: 1.5rem 1.5rem;
    }
  }
}
