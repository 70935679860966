@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list_item_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  .list_item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .type_container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 1rem;
      }
      .text {
        padding: 0;
        margin: 0;
        font-size: $FONT-SIZE-MD;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: $COLOR-GRAY-500;
      }
    }
    .value_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .data {
        color: $COLOR-GRAY-500;
        font-weight: 500;
        font-size: $FONT-SIZE-XL;
        margin-bottom: 0;
        letter-spacing: 0.1rem;
      }
      .unit {
        margin-left: 1rem;
        font-size: $FONT-SIZE-MD;
        letter-spacing: 0.1px;
        color: $COLOR-GRAY-350;
        text-align: right;
        min-width: 35px;
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: $FONT-SIZE-XXXL;
  color: $COLOR-GRAY-350;
}

.circle_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.circles_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .column {
    display: flex;
    flex-direction: column;
    .chart_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
