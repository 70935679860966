@import 'styles/Base';

.root {
  padding: $LAYOUT-CONTENT-PADDING;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  gap: 2rem;
  .widget_opt_power {
    grid-column: span 2;
  }
  .widget_maxim {
    grid-column: span 2;
  }
  .widget_contract {
    grid-column: span 2;
  }
  .widget_consum_mensual {
    grid-column: span 3;
  }
  .widget_consum_mensual_Tarifa {
    grid-column: span 3;
  }

}
@media (min-width: 1024px) and (max-width: 1420px) {
  // .root {

  // padding: 2rem;
  // display: flex;
  // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  // grid-auto-rows: minmax(min-content, max-content);
  // grid-gap: 2rem;
  // gap: 2rem;
  // flex-direction: column;
  // flex-wrap: nowrap;
  // justify-content: space-between;
  // }
  .widget_opt_power {
    grid-column: span 2;
  }
  .widget_maxim {
    grid-column: span 2;
  }
  .widget_contract {
    grid-column: span 2;
  }
  .widget_consum_mensual {
    grid-column: span 5 !important;
  }
  .widget_consum_mensual_Tarifa {
    grid-column: span 5 !important;
  }
}
