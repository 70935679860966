@import 'styles/Base';

.error_message {
  text-align: center;
}

.loading_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.root {
  margin: $LAYOUT-CONTENT-PADDING auto;
  display: flex;
  flex-direction: column;
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }

  .header {
    margin: $LAYOUT-CONTENT-PADDING;
    margin-bottom: 0;
  }
  .content {
    margin: $LAYOUT-CONTENT-PADDING;
    margin-bottom: 0;
    min-height: 30rem;
    background-color: $COLOR-WHITE;
  }
}
