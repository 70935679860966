@import 'styles/Base';

.root {
  z-index: 9999999;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  &.dev {
    background-color: $COLOR-GENERACIO;
  }
  .value {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.responsive_reporter {
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: gray;
  @include desktop() {
    background-color: cyan;
  }
  @include tablet() {
    background-color: yellow;
  }
  @include mobile() {
    background-color: red;
  }
}
