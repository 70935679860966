@import 'styles/Base';

.container {
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  padding: $LAYOUT-CONTENT-PADDING;
  margin: 0 auto;
  flex: 1;
  max-height: 100%;
  width: 100%;
  background-color: $COLOR-WHITE;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 2rem;
  }
  .table_wrapper {
    overflow-y: scroll;
    width: 100%;
    margin-bottom: $SPACING-SM;
    flex: 1;
    max-height: 60vh;
    &::-webkit-scrollbar-thumb {
      background: $COLOR-ELECSUMGREEN;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
  }
}
