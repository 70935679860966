@import 'styles/Base';

.mainrow {
  border-top: 1px solid $COLOR-GREY-MEDIUM;
  border-bottom: 1px solid $COLOR-GREY-MEDIUM;

  td {
    padding: 1rem 0;
    font-size: $FONT-SIZE-MD;
    font-weight: 400;
    color: $COLOR-GREY-DARK;
    &:first-child {
      padding-left: 1.5rem;
    }
    &.collapse {
      button {
        background-color: transparent;
        border: none;
        padding: 0 0.5rem;

        svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: $COLOR-ELECSUMGREEN;
        }
      }
    }
    &.cups {
      color: $COLOR-GREY-DARK;
      font-weight: 500;
    }
    .actions {
    }
  }
  // Checkbox Table
  .checkboxTable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $FONT-SIZE-MD;
    font-weight: 400;
    text-transform: uppercase;
    color: $COLOR-GRAY-500;
    margin-bottom: 0;
    input[type='checkbox'] {
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      width: 2rem;
      height: 2rem;
      border: 2px solid $COLOR-GRAY-250;
      color: $COLOR-WHITE;
      margin-left: 0;
      background-color: $COLOR-WHITE;
      margin-right: 0.5rem;
      &:focus {
        outline: none;
      }
      &:checked + span {
        color: $COLOR-BLACK;
        font-weight: 500;
      }
    }
    input[type='checkbox']::before {
      content: '\2714';
      position: absolute;
      font-size: $FONT-SIZE-MD;
      visibility: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input[type='checkbox']:checked::before {
      visibility: visible;
      background-color: $COLOR-ELECSUMGREEN;
    }
  }
}
// .imposible {
//   background-color: #b71c1c;
//   td {
//     color: white;
//   }
// }
