@import 'styles/Base';

.mainrow {
  border-top: 1px solid $COLOR-GREY-MEDIUM;
  border-bottom: 1px solid $COLOR-GREY-MEDIUM;
  &.even {
    background-color: $COLOR-WHITE;
  }
  &.odd {
    background-color: $COLOR-GRAY-000;
  }

  td {
    padding: 1rem 0;
    font-size: $FONT-SIZE-MD;
    font-weight: 400;
    color: $COLOR-GREY-DARK;
    &:first-child {
      padding-left: 1.5rem;
    }
    &.collapse {
      button {
        background-color: transparent;
        border: none;
        padding: 0 0.5rem;

        svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: $COLOR-ELECSUMGREEN;
        }
      }
    }
    &.cups {
      color: $COLOR-GREY-DARK;
      font-weight: 500;
    }
    .actions {
    }
  }
}

.collapsedrow {
  &.even {
    background-color: $COLOR-WHITE;
  }
  &.odd {
    background-color: $COLOR-GRAY-000;
  }
  .content {
    padding-left: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 30rem));
    column-gap: $SPACING-MD;
    padding: 1rem;

    .info {
      padding: 0 1rem;
      flex: 1;
      display: grid;
      gap: 1rem;
      grid-template-columns: 25rem;
    }
    .actions {
    }
  }
}

.labeled_value_item {
  font-size: $FONT-SIZE-MD;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  justify-content: space-between;
  color: $COLOR-GREY-DARK;
  .label {
    font-weight: 500;
  }
  .value {
    text-align: right;
  }
}

.sizeButton {
  width: auto;
  padding-right: 1rem !important;
}
