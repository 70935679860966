@import 'styles/Base';

.emissions_card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  .section {
    //"row justify-content-center align-items-center"
    .value_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      .text {
        margin-left: 0.5rem;
      }
    }
    .data {
      font-weight: 500;
      font-size: $FONT-SIZE-XL;
    }
    .text {
      font-size: $FONT-SIZE-MD;
      color: $COLOR-GRAY-350;
    }
    .text_bold {
      font-size: $FONT-SIZE-MD;
      color: $COLOR-GRAY-500;
    }
  }
}
