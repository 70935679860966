@import 'styles/Base';

.root {
  max-width: 1140px;
  margin: 2rem auto;
  .divider {
    width: 40px;
    height: 2px;
    background-color: $COLOR-ELECSUMGREEN;
    margin: 0 auto 2rem;
  }
  .about,
  .environment {
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 34px;
    opacity: 0.87;
    color: $COLOR-BLACK;
    margin-bottom: 2rem;
  }
  .environment {
    text-transform: none;
  }
  .carousel_wrapper {
    margin-bottom: 2rem;
    .carousel_inner {
      background-color: transparent;
      li {
        background-color: transparent;
      }
    }
  }
  .company-description {
    max-width: 700px;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    .description {
      font-size: $FONT-SIZE-LG;
      line-height: 1.5;
      color: $COLOR-BLACK;
      text-align: justify;
    }
  }
}

// MOBILE

@media (max-width: 767px) {
  .root {
    .about,
    .environment {
      font-size: $FONT-SIZE-XL;
    }
  }
}
