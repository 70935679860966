@import 'styles/Base';

.invoice_wrapper {
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  min-width: 50rem;
  .resume {
    .invoice_period_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 0.5px;
      font-weight: 500;
      .period {
        display: flex;
        flex-direction: row;
        font-size: $FONT-SIZE-SM;
        span {
          color: $COLOR-BLACK;
          text-transform: uppercase;
          margin-right: 1rem;
        }
      }
      .logo {
      }
    }
    .invoice_number_wrapper {
      display: flex;
      flex-direction: row;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
      font-weight: 500;
      margin: 1rem 0;
      .number_label {
        margin-right: 1rem;
      }
      .number {
      }
    }
    .invoice_date_status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .invoice_date_wrapper {
        display: flex;
        flex-direction: row;
        color: $COLOR-GRAY-500;
        font-size: $FONT-SIZE-MD;
        font-weight: 500;
        margin: 1rem 0;
        .date_label {
          margin-right: 1rem;
        }
      }
      .invoice_status_wrapper {
        .stamp {
          font-size: $FONT-SIZE-SM;
          text-transform: uppercase;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem 1rem;
          font-weight: 600;
          border-width: 1.5px;
          border-style: solid;
          border-radius: 0.4rem;
          span {
            margin: 0 1rem;
          }
        }
        .pagada {
          background-color: $COLOR-ELECSUMLOW;
          border-color: $COLOR-ELECSUMGREEN;
          color: $COLOR-ELECSUMGREEN;
        }
        .pendent {
          background-color: $COLOR-YELLOW-LOW;
          color: $COLOR-GRAY-600;
          border-color: $COLOR-GRAY-600;
        }
        .no_pagada {
          background-color: rgba($COLOR-ERROR, 0.05);
          border-color: $COLOR-ERROR;
          color: $COLOR-ERROR;
        }
      }
    }
  }

  .concepts {
    margin: 1.5rem 0;
    .concept {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1rem 0;
      .data {
        color: $COLOR-GRAY-500;
        font-size: $FONT-SIZE-MD;
      }
      .value {
        font-weight: 400;
        color: $COLOR-BLACK;
      }
    }
  }
  .footer_invoice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;

    .total {
      color: $COLOR-GRAY-500;
      font-weight: 500;
      text-transform: uppercase;
    }
    .value {
      font-weight: 500;
    }
  }
  .download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      &:hover {
        @include hover_clear_shadow();
      }
      font-size: $FONT-SIZE-LG;
      background-color: transparent;
      border-radius: 0.8rem;
      border: none;
      color: $COLOR-ELECSUMGREEN;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      p {
        margin: 0 2rem;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: $COLOR-GRAY-100;
    width: 100%;
    margin: 1rem auto;
  }
}
