@import 'styles/Base';

.root {
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: $sidebar-padding;
  color: $COLOR-BLACK;
  background: $COLOR-WHITE;
  border-right: 1px solid rgba($COLOR-GRAY-050, 0.5);
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  .nav {
    display: flex;
    flex-direction: column;
  }
}

.NavItemDivider {
  height: 1px;
  background-color: $COLOR-GRAY-100;
}

// Link Without Dropdown
.NavItemLink {
  position: relative;
  margin: 0;
  .link {
    align-items: center;

    border-left: 4px solid $COLOR-TRANSPARENT;
    color: $COLOR-GRAY-500;
    display: flex;
    flex-direction: row;
    font-size: $FONT-SIZE-MD;
    font-weight: 400;
    height: 4rem;
    padding: 1rem 1rem;
    span {
      margin-left: 1rem;
    }
    &.active,
    &:hover {
      color: $COLOR-BLACK;
      background-color: rgba($COLOR-ELECSUMGREEN, 0.1);
      border-left: 4px solid $COLOR-ELECSUMGREEN;
      font-weight: 500;
      i {
        color: $sidebar-nav-link-active-icon-color;
      }
    }
  }
}

.NavItemDropdown {
  &.active {
    .NavItemLink {
      .link {
        background-color: $COLOR-ELECSUMLOW;
        border-left: 4px solid $COLOR-ELECSUMGREEN;
      }
    }
  }
  .NavItemLink {
    position: relative;
    margin: 0;
    .link {
      align-items: center;
      color: $COLOR-GRAY-500;
      font-size: $FONT-SIZE-MD;
      font-weight: 300;
      height: 4rem;
      padding-left: 1rem;
      span {
        margin-left: 1rem;
      }
      &.active,
      &:hover {
        color: $COLOR-BLACK;
        background-color: #e8eedd;

        font-weight: 400;
        i {
          color: $sidebar-nav-link-active-icon-color;
        }
      }
    }
  }

  .button {
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: 0;
    padding: 1rem 1rem;
    border-left: 4px solid $COLOR-TRANSPARENT;
    background-color: $COLOR-WHITE;
    color: $COLOR-GRAY-500;
    svg {
      fill: $COLOR-GRAY-100;
    }
    &:hover {
      color: $COLOR-BLACK;
      background-color: #e8eedd;
      font-weight: 400;
      border-left: 4px solid $COLOR-ELECSUMGREEN;
    }
    &.active {
      background-color: $COLOR-ELECSUMGREEN;
      border-left: 2px solid $COLOR-ELECSUMGREEN;
      color: $COLOR-WHITE;
      font-weight: 500;
      svg {
        fill: white;
      }
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: $FONT-SIZE-MD;
      span {
        margin-left: 1rem;
      }
    }
    .toggleIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
    }
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    top: 0;
    display: none;
    &.show {
      display: block;
    }
  }
}
