@import 'styles/Base';

.root {
  padding: $LAYOUT-CONTENT-PADDING;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  gap: 2rem;
  overflow: auto;
  .alerts_widget {
    grid-column: span 2;
  }
  .users_widget {
    grid-column: span 2;
  }
  .communities_widget {
    grid-column: span 3;
  }
}
