@import 'styles/Base';

.image-file {
  position: relative;
  .image-file-input {
    display: none;
    width: 100%;
  }

  .image-file-label {
    margin-bottom: 0;
    .image-placeholder {
      display: flex;
      flex-direction: row;
      .icon-wrapper {
        &.background-img-type {
          padding: 0;
          align-items: flex-start;
          .icon-wrapp {
            width: 100%;
            height: 41px;
            border-radius: 2px 2px 0 0;
          }
        }
        border: 1px solid $COLOR-GRAY-100;

        background-color: $COLOR-GRAY-050;
        border-radius: 2px;
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        min-width: 100px;
        height: 60px;
        justify-content: center;
        .icon-wrapp {
          background-color: $COLOR-GRAY-100;
          border-radius: 100%;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img-preview {
          position: absolute;
          width: 100px;
          height: 60px;
          z-index: 1;
          background-color: $COLOR-WHITE;
          border: 1px solid $COLOR-GRAY-100;
          border-radius: 2px;
          img {
            border-radius: 2px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .text-placeholder {
        margin-left: 1rem;
        .placeholder-label {
          font-size: $FONT-SIZE-MD;
          font-weight: 500;
          color: $COLOR-ELECSUMGREEN;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        .placeholder-note {
          margin-bottom: 1rem;
          font-size: $FONT-SIZE-SM;
          color: $COLOR-GRAY-700;
          font-style: italic;
        }
        .placeholder-picked {
          font-size: $FONT-SIZE-SM;
          font-weight: 700;
          color: $COLOR-BLACK;
          font-style: italic;
        }
      }
    }
  }
}
