@import './Variables.scss';
@import './Typography.scss';

// fonts

.font-xs {
  font-size: $FONT-SIZE-XS;
}
.font-sm {
  font-size: $FONT-SIZE-SM;
}
.font-md {
  font-size: $FONT-SIZE-MD;
}
.font-lg {
  font-size: $FONT-SIZE-LG;
}
.font-xl {
  font-size: $FONT-SIZE-XL;
}
.font-xxl {
  font-size: $FONT-SIZE-XXL;
}
.font-bold {
  font-weight: bold;
}

// utils
@mixin hover_clear_shadow {
  background-color: rgba($COLOR-ELECSUMSTRONG, 0.1);
  box-shadow: 0rem 0rem 1rem rgba($COLOR-BLACK, 0.04);
}

@mixin not_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clicable {
  &:hover {
    cursor: pointer;
  }
}
