@import './Variables.scss';

h1 {
  font-size: 9.6rem;
  font-weight: 300;
}

h2 {
  font-size: 6rem;
  font-weight: 300;
}

h3 {
  font-size: 4.8rem;
  font-weight: 400;
}

h4 {
  font-size: 3.4rem;
  font-weight: 400;
}

h5 {
  font-size: $FONT-SIZE-XL;
  font-weight: 400;
}

h6 {
  font-size: $FONT-SIZE-LG;
  font-weight: 400;
}
