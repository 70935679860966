@import 'styles/Base';

.titleWrapper {
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 1.5rem 0;
}
.table_wrapper2 {
  //overflow-y: scroll;
  width: 100%;
  margin-bottom: $SPACING-SM;
  flex: 1;
  height: 75vh;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
}
.table_wrapper {
  width: 100%;
  border-collapse: collapse;
  thead {
    position: sticky;
    top: 70px;
    tr {
      th {
        background-color: $COLOR-WHITE;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 1.5rem 0;
        text-align: left;
        font-size: $FONT-SIZE-MD;
        font-weight: 700;
        color: black;
        text-transform: uppercase;
        &:first-child {
          padding-left: 1.5rem;
        }
      }
    }
  }
  .nodata {
    text-align: center;
    width: 100%;
    padding: 2rem;
  }
}
