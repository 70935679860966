@import 'styles/Base';

.root {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 0.5rem 0;
  svg {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    fill: $COLOR-ELECSUMGREEN;
    color: $COLOR-GRAY-500;
  }
  .label {
    font-size: $FONT-SIZE-MD;
    text-decoration: underline;
    &:hover {
      color: $COLOR-ELECSUMGREEN;
      text-decoration-color: $COLOR-ELECSUMGREEN;
    }
  }
  .consumoAnual {
    margin-top: 1rem;
    color: #555555;
    font-size: 14px;
  }
}

.content {
  max-width: 55rem;
  p {
    width: 100%;
    margin-top: 1rem;
  }
  img {
    width: 100%;
  }
}

.contentOferta {
  p {
    width: 100%;
    margin-top: 1rem;
  }
  img {
    width: 100%;
  }
}

.contentInput {
  max-width: 30rem;

  p {
    width: 100%;
    margin-top: 1rem;
  }
  img {
    width: 100%;
  }
  ul {
    width: 100%;
    margin-top: 1rem;
  }
  li {
    width: 100%;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}
