@import 'styles/Base';

.modal {
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #c0c0c050;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.modaldialogcentered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modaldialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;

  top: 45%;

  transform: translate(0, -50%);
}
.modalcontent {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  //background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  max-height: calc(100vh - 100px); // El modal ocupa toda la pantalla menos 100px
  overflow: scroll;
  &::-webkit-scrollbar-thumb {
    background: $COLOR-ELECSUMGREEN;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
}
.modalheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 20px;
}
.modalbody {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modalfooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
@media (min-width: 576px) {
  .modaldialog {
    max-width: 900px;
    margin: 1.75rem auto;
  }
  .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 40px;
  }
}
.botonModal {
  button {
    border-radius: 30px;
  }
}
