@import 'styles/Base';

.root {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      border-bottom: 1px solid $COLOR-GRAY-600;
      tr {
        .title {
          font-size: $FONT-SIZE-SM;
          line-height: 1.33;
          letter-spacing: 0.4px;
          color: $COLOR-BLACK;
          opacity: 0.87;
          border: none;
          text-align: right;
          padding: 1rem;
          padding-left: 1.25rem;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            padding-right: 2rem;
          }
        }
      }
    }
    .t_body {
      .t_row {
        border: solid 1px $COLOR-GRAY-100;
        background-color: $COLOR-GRAY-000;

        &:first-child {
          border-top: none;
          .t_content:first-child {
            font-weight: 700;
          }
          .t_content:last-child {
            .units,
            .data {
              display: none;
            }
          }
        }
        &:hover {
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
          border: solid 1px $COLOR-GRAY-050;
          background-color: $COLOR-WHITE;
        }
        &.clicable:hover {
          cursor: pointer;
        }
        .t_content {
          text-align: right;
          color: $COLOR-GRAY-500;
          border: none;
          padding: 1rem;
          font-size: $FONT-SIZE-LG;
          line-height: 1.2em;
          letter-spacing: 0.44px;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 50rem;
          max-height: 2em;

          button {
            font-size: $FONT-SIZE-MD;
            line-height: 1.43;
            letter-spacing: 0.25px;
            color: $COLOR-ELECSUMGREEN;
            border: none;
            vertical-align: middle;
            padding-left: 1.25rem;
            text-align: left;
            background-color: transparent;
          }

          .picker {
            background-color: $COLOR-WHITE;
          }

          &:first-child {
            font-size: $FONT-SIZE-MD;
            line-height: 1.43;
            letter-spacing: 0.25px;
            color: $COLOR-ELECSUMGREEN;
            border: none;
            vertical-align: middle;
            padding-left: 1.25rem;
            text-align: left;
          }
          &:last-child {
            padding-right: 2rem;
            align-content: center;
          }
          .units {
            font-size: $FONT-SIZE-MD;
            font-weight: 500;
            margin-left: 0.25rem;
            letter-spacing: 0.1px;
          }
        }
        .t_content_html {
          text-align: justify;
          color: $COLOR-GRAY-500;
          border: none;
          padding: 1rem;
          font-size: $FONT-SIZE-LG;
          line-height: 1.2em;
          letter-spacing: 0.44px;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 50rem;
          max-height: 2em;

          button {
            font-size: $FONT-SIZE-MD;
            line-height: 1.43;
            letter-spacing: 0.25px;
            color: $COLOR-ELECSUMGREEN;
            border: none;
            vertical-align: middle;
            padding-left: 1.25rem;
            text-align: left;
            background-color: transparent;
          }

          .picker {
            background-color: $COLOR-WHITE;
          }

          &:first-child {
            font-size: $FONT-SIZE-MD;
            line-height: 1.43;
            letter-spacing: 0.25px;
            color: $COLOR-ELECSUMGREEN;
            border: none;
            vertical-align: middle;
            padding-left: 1.25rem;
            text-align: left;
          }
          &:last-child {
            padding-right: 2rem;
            align-content: center;
          }
          .units {
            font-size: $FONT-SIZE-MD;
            font-weight: 500;
            margin-left: 0.25rem;
            letter-spacing: 0.1px;
          }
        }
        td {
          img.image_cell {
            width: auto;
            height: auto;
            max-width: 100px;
            max-height: 100px;
          }
          p.colorpdf {
            color: black;
          }
        }
      }
    }
  }
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
}
