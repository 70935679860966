@import 'styles/Base';

th {
  text-align: left;
}
table {
  width: 50%;
}
// Checkbox Table
.checkboxTable {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  text-transform: uppercase;
  color: $COLOR-GRAY-500;
  margin-bottom: 0;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    margin-left: 3rem;
    background-color: $COLOR-WHITE;
    margin-right: 0.5rem;
    &:focus {
      outline: none;
    }
    &:checked + span {
      color: $COLOR-BLACK;
      font-weight: 500;
    }
  }
  input[type='checkbox']::before {
    content: '\2714';
    position: absolute;
    font-size: $FONT-SIZE-MD;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type='checkbox']:checked::before {
    visibility: visible;
    background-color: $COLOR-ELECSUMGREEN;
  }
}
