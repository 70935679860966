// FONT SIZES
$FONT-SIZE-XS: 1rem;
$FONT-SIZE-SM: 1.2rem;
$FONT-SIZE-MD: 1.4rem;
$FONT-SIZE-LG: 1.6rem;
$FONT-SIZE-XL: 2rem;
$FONT-SIZE-XXL: 2.4rem;
$FONT-SIZE-XXXL: 3rem;

// SPACING
$SPACING-XS: 0.5rem;
$SPACING-SM: 1rem;
$SPACING-MD: 1.5rem;
$SPACING-LG: 2.5rem;
$SPACING-XL: 4rem;
$SPACING-XXL: 5.5rem;
$SPACING-XXXL: 7.5rem;

// LAYOUT SPACING
$LAYOUT-CONTENT-PADDING: 2rem;
$LAYOUT-CONTENT-MAXWIDTH-DESKTOP: 144rem;

// NAVBAR
$NAVBAR-HEIGHT: 7rem;
$NAVBAR-BRAND-WIDTH: 20rem;

// SIDEBAR
$sidebar-width: $NAVBAR-BRAND-WIDTH;
$sidebar-padding: 0 !default;

// SIDEBAR HEADER
$sidebar-header-height: auto !default;
$sidebar-header-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-header-padding-y: 1rem !default;
$sidebar-header-padding-x: 1.25rem !default;

// Sidebar Navigation
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-active-icon-color: theme-color('primary') !default;
