@import 'styles/Base';

.table_wrapper {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        background-color: $COLOR-WHITE;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 1.5rem 0;
        text-align: left;
        font-size: $FONT-SIZE-MD;
        font-weight: 700;
        color: black;
        text-transform: uppercase;
        &:first-child {
          padding-left: 1.5rem;
        }
      }
    }
  }
  .nodata {
    text-align: center;
    width: 100%;
    padding: 2rem;
  }
}
