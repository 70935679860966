@import 'styles/Base';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  background-color: $COLOR-GRAY-000;

  height: calc(100vh - #{$NAVBAR-HEIGHT});
  max-height: calc(100vh - #{$NAVBAR-HEIGHT});
  overflow: hidden;
}
