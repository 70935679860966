@import 'styles/Base';

.RecoverPassword {
  .navbar {
    background-image: linear-gradient(123deg, $COLOR-ELECSUMGREEN 58%, $COLOR-GRADIENT-YELLOW 129%);

    img {
      width: 100px;
      height: auto;
    }
  }
}

.root {
  padding: $LAYOUT-CONTENT-PADDING;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  .head {
    background-image: linear-gradient(123deg, $COLOR-ELECSUMGREEN 58%, $COLOR-GRADIENT-YELLOW 129%);
    img {
      width: 100px;
      height: auto;
    }
  }

  .form_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form {
      margin-top: 10%;
      max-width: 50rem;
      background-color: white;
      padding: 2rem;
      .passwords {
        & > * {
          margin-bottom: 3.5rem;
        }
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 2.5rem;
      }
    }
  }
}
