@import 'styles/Base';

.CompanyNew {
  background-color: $COLOR-WHITE;
  box-shadow: 0 0px 6px 0 rgba($COLOR-BLACK, 0.1);
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  .img_wrapper {
    max-height: 30rem;
    margin: 0 auto;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .info_container {
    padding: 1rem;
    .date {
      font-size: $FONT-SIZE-SM;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: $COLOR-GRAY-500;
      opacity: 0.87;
    }
    .headline {
      font-weight: 700;
      font-size: $FONT-SIZE-XXL;
      line-height: 1.33;
      color: $COLOR-BLACK;
      opacity: 0.87;

      margin: 1rem 0;
    }
    .description {
      font-size: $FONT-SIZE-LG;
      line-height: 1.5;
      color: $COLOR-BLACK;
    }
  }
}

// MOBILE

@media (max-width: 767px) {
  .CompanyNew {
    .img-wrapper {
      width: 100%;
      height: auto;
    }
  }
}
