@import 'styles/Base';

.loading_container,
.root {
  padding: $LAYOUT-CONTENT-PADDING;
}

.loading_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.root {
  max-width: $LAYOUT-CONTENT-MAXWIDTH-DESKTOP;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
  gap: 2rem;
  .user_info {
    display: grid;
    grid-template-columns: minmax(30rem, 1fr);
    row-gap: 1.5rem;
    padding-top: 10px;
    .field {
    }
  }
  .rootSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $COLOR-GRAY-100;
    border-radius: 0.2rem;
    background-color: transparent;
    padding: 0 1rem;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
    }
    select {
      background-color: transparent;
      color: $COLOR-GRAY-500;
      border: none;
      display: flex;
      flex-direction: row;
      font-size: $FONT-SIZE-MD;
      font-weight: 200;
      justify-content: space-between;
      letter-spacing: 0.44px;
      padding: 1.25rem 1rem;
      width: 100%;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .saveContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.4rem;
  }
  .buttonsContainer {
    display: flex;
    gap: 10px;
  }
  .table_wrapper {
    .nodata {
      color: $COLOR-GRAY-600;
      padding: 2rem 0;
    }
    grid-column: span 2;
    table {
      border-collapse: collapse;
      width: 100%;
      thead {
        border-bottom: 1px solid $COLOR-GRAY-600;
        tr {
          th {
            padding: 1.5rem 0;
            text-align: left;
            &:first-child {
              padding-left: 1.5rem;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1.5px solid $COLOR-GRAY-100;
          border-top: 1.5px solid $COLOR-GRAY-100;
          &:nth-child(odd) {
            background-color: $COLOR-WHITE;
          }
          &:nth-child(even) {
            background-color: $COLOR-GRAY-000;
          }
          &:hover {
            background-color: $COLOR-ELECSUMLOW;
            cursor: pointer;
            td {
              color: $COLOR-GRAY-700;
            }
          }
          td {
            padding: 2rem 0;
            font-size: $FONT-SIZE-MD;
            font-weight: 400;
            color: $COLOR-GRAY-500;
            &:first-child {
              padding-left: 1.5rem;
            }
            &.sistemaOperativo {
            }
          }
        }
      }
    }
  }
}

.Sinboder {
  border-bottom: none !important;
  td {
    font-size: $FONT-SIZE-LG !important;
  }
}

.comunidadesMarginTop {
  margin-top: 2rem;
}

.urlMapa {
  margin-bottom: 1rem;

  .shareButton {
    padding: 0px;
    background-color: transparent;
    color: $COLOR-ELECSUMGREEN;
    height: 3.5rem;
    background-color: transparent;
    border: none;
    font-size: $FONT-SIZE-MD;
    font-weight: 700;
    border-radius: 5rem;
    align-self: center;
  }
}

// Checkbox Table
.checkboxTable {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: $FONT-SIZE-MD;
  font-weight: 400;
  text-transform: uppercase;
  color: $COLOR-GRAY-500;
  margin-bottom: 0;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid $COLOR-ELECSUMGREEN;
    color: $COLOR-WHITE;
    margin-left: 2rem;
    background-color: $COLOR-WHITE;
    margin-right: 0.5rem;
    &:focus {
      outline: none;
    }
    &:checked + span {
      color: $COLOR-BLACK;
      font-weight: 500;
    }
  }
  input[type='checkbox']::before {
    content: '\2714';
    position: absolute;
    font-size: $FONT-SIZE-MD;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type='checkbox']:checked::before {
    visibility: visible;
    background-color: $COLOR-ELECSUMGREEN;
  }
}
