@import 'styles/Base';

.sectionInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .value {
    margin-top: 0.5rem;
    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .data {
      font-weight: 500;
      color: $COLOR-BLACK;
      font-size: $FONT-SIZE-XL;
    }
    .text {
      font-size: $FONT-SIZE-MD;
      color: $COLOR-GRAY-350;
      margin-left: 0.5rem;
    }
  }
  .bold {
    font-size: $FONT-SIZE-MD;
    color: $COLOR-GRAY-500;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sections_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.view_more_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  .viewmore_button {
    background-color: transparent;
    color: $COLOR-ELECSUMGREEN;
    &:hover {
      @include hover_clear_shadow();
    }
  }
}
