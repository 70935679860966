@import 'styles/Base';

.root {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        border-bottom: 1px solid $COLOR-GRAY-600;
        th {
          font-size: $FONT-SIZE-SM;
          line-height: 1.6rem;
          color: $COLOR-BLACK;
          font-weight: 400;
          letter-spacing: 0.4px;
          text-align: right;
          padding: 1.5rem;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        border: solid 1px $COLOR-GRAY-100;
        background-color: $COLOR-GRAY-000;
        &:hover {
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
          border: solid 1px $COLOR-GRAY-050;
          background-color: $COLOR-WHITE;
          cursor: pointer;
        }
        td {
          text-align: right;
          padding: 2rem 1.5rem;
          color: $COLOR-GRAY-400;
          font-size: $FONT-SIZE-MD;
          &:first-child {
            color: $COLOR-ELECSUMGREEN;
            text-align: left;
            font-weight: 500;
          }
        }
      }
    }
  }
}
